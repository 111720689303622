const modulesData = [
	{
		id: 's2-m1',
		background: 'production',
		title: {
			da: 'Transport',
			en: 'Transport',
		},
		minStars: 3,
		requiredModuleIds: [],
		tasks: require('./modules/module-1').moduleTasks,
	},
]

const moduleGroups = [
	{
		id: 's2-mg1',
		courseNumber: 'xxxxx',
		title: {
			da: 'Transport',
			en: 'Transport',
		},
		moduleIds: [
			's2-m1'
		]
	}
];

export {
	modulesData,
	moduleGroups
};