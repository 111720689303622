// NOTE: remember to update the UI-texts file on the drive, if you update this file */

const errorUiTexts = {
	/* Unknown error */
	unknownError: {
		da: 'Fejl: ukendt fejl.',
		en: 'Error: unknown error.',
	},

	/* Cookies not accepted */
	cookiesNotAccepted: {
		da: `Du skal godkende brug af cookies for at kunne logge ind.`,
		en: 'You have to accept use of cookies to log in.',
	},

	/* Unknown user / game */
	unknownUserOrGame: {
		da: `Fejl: ukendt bruger eller spil.`,
		en: `Error: unknown user or game.`
	},

	/* Admin - add facilitator */
	invalidEmail: {
		da: 'E-mail er ikke gyldig.',
		en: 'E-mail is not valid.',
	}
};

module.exports = {
	errorUiTexts
};