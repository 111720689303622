const taskTemplates = require('data/templates/task-templates');
const {effectTemplate} = require('data/templates/effect-template');

let moduleTasks = [
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		taskId: 's3-m1-instructions-welcome',
		showProgressBar: false,
		background: 'outside',
		backgroundModifier: 'bottom',
		text: {
			da: `Velkommen til hygiejnetræningen for produktion. Jeg er din teamleder og hjælper dig gennem spillet.`,
			en: ``,
		}
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		taskId: 's3-m1-instructions-points',
		showProgressBar: false,
		background: 'outside',
		backgroundModifier: 'bottom',
		text: {
			da: `I spillet får du point for at løse opgaver. Jo færre fejl du laver, jo flere point får du.`,
			en: ``
		}
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		taskId: 's3-m1-instructions-stars',
		background: 'outside',
		backgroundModifier: 'bottom',
		characters: ['manager pose-2 coat-2'],
		text: {
			da: `Når du får point, kan du også få stjerner. I toppen af skærmen kan du se, hvor mange stjerner og point du har.`,
			en: ``
		}
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		taskId: 's3-m1-instructions-production-flow',
		background: 'outside',
		backgroundModifier: 'bottom',
		text: {
			da: `I løbet af spillet følger vi produktionens flow. Så vi starter med modtagelse af varer.`,
			en: ``
		}
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 's3-m1-mc-damaged-goods',
		background: 'outside',
		backgroundModifier: 'bottom',
		text: {
			da: `Hvis du modtager en beskadiget vare, hvad skal du så gøre?`,
			en: ``,
		},
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1,
				text: {
					da: `Afvise den`,
					en: ``
				},
				isCorrect: true,
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							text: {
								da: `Hvis det er sækkevarer, så må du gerne modtage de ubeskadigede sække og så afvise resten.`,
								en: ``,
							}
						})
					})
				]
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2,
				text: {
					da: `Fjerne den beskadigede del og bruge resten`,
					en: ``
				},
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3,
				text: {
					da: `Modtage den, men lave en anmærkning`,
					en: ``
				},
			}),
		]
	}),
	Object.assign({}, taskTemplates.spotErrorsTemplate.mainTemplate, {
		taskId: 's3-m1-spot-errors-truck',
		layout: 's1-m1-truck',
		background: 'outside',
		backgroundModifier: 'bottom',
		text: {
			da: `Prøv at se om du kan spotte de 3 problemer på det her billede. Tryk OK, når du har dem, eller hvis du ikke kan finde dem alle.`,
			en: ``,
		},
		errors: [
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {id: 'hole'}),
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {id: 'mould'}),
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {id: 'dirt'})
		]
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 's3-m1-mc-mould',
		layout: 's1-m1-mould',
		subtype: 'images',
		background: 'outside',
		backgroundModifier: 'bottom',
		text: {
			da: `Hvis der er synlig mug eller klumper, så skal du afvise partiet. Men hvad kan også hjælpe dig til at spotte mug og svampepartikler?`,
			en: ``,
		},
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1, // Nose
				isCorrect: true,
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							text: {
								da: `Nemlig. Mug og skimmel har en kraftig og distinkt lugt, som du nemt kan genkende. Så brug næsen når du modtager varer.`,
								en: ``,
							}
						})
					})
				]
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2, // Magnifying glass
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3, // Microscope
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4, // Ear
			}),
		]
	}),

	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 's3-m1-mc-copy-sample-seal',
		background: 'outside',
		backgroundModifier: 'bottom',
		text: {
			da: `Når du modtager råvarer, skal der laves en kopiprøve. Hvilke af dem skal plomberes?`,
			en: ``,
		},
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1,
				text: {
					da: `Alle kopiprøver`,
					en: ``
				},
				isCorrect: true,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2,
				text: {
					da: `Kun GMP+`,
					en: ``
				},
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3,
				text: {
					da: `Kun økologiske`,
					en: ``
				},
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4,
				text: {
					da: `Kun VLOG`,
					en: ``
				},
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 5,
				text: {
					da: `VLOG og GMP+`,
					en: ``
				},
			}),
		]
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 's3-m1-mc-analysis-document-dioxin',
		background: 'outside',
		backgroundModifier: 'bottom',
		text: {
			da: `Hvilken type vare kræver at der medfølger et analysebevis for dioxin?`,
			en: ``,
		},
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1,
				text: {
					da: `Fedt`,
					en: ``
				},
				isCorrect: true
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2,
				text: {
					da: `Melasse`,
					en: ``
				},
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3,
				text: {
					da: `Aromastoffer`,
					en: ``
				},
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4,
				text: {
					da: `Foderkridt`,
					en: ``
				},
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							text: {
								da: `Med foderkridt modtager du en erklæring på, at det ikke indeholde dioxin, men ikke et analysebevis.`,
								en: ``,
							}
						})
					})
				]
			}),
		]
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		taskId: 's3-m1-instructions-silo-filling',
		background: 'outside',
		backgroundModifier: 'bottom',
		text: {
			da: `Okay, lad os gå videre til silopåfyldning.`,
			en: ``
		}
	}),
	Object.assign({}, taskTemplates.orderTemplate.mainTemplate, {
		taskId: 's3-m1-order-silo-filling',		
		text: {
			da: `Hvad er den korrekte procedure ved silopåfyldning? Sæt nedenstående håndlinger i den rigtige rækkefølge. Tryk OK, når du er klar.`,
			en: ``,
		},
		items: [
			Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
				id: 1,
				text: {
					da: `Hent vare på lageret`,
					en: ``
				},
				correctOrderNumbers: [1]
			}),
			Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
				id: 2,
				text: {
					da: `Tjek varenavn, mængde og holdbarhed`,
					en: ``,
				},
				correctOrderNumbers: [2]
			}),
			Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
				id: 3,
				text: {
					da: `Check siloplan i operatørrum og åbn transportveje`,
					en: ``,
				},
				correctOrderNumbers: [3]
			}),
			Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
				id: 4,
				text: {
					da: `Hvis ny vare, check at påslag er tomt og rengjort`,
					en: ``,
				},
				correctOrderNumbers: [4]
			}),
			Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
				id: 5,
				text: {
					da: `Påfyld vare`,
					en: ``,
				},
				correctOrderNumbers: [5]
			}),
		]
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 's3-m1-mc-fifu',
		text: {
			da: `Når du sætter sække på lageret gælder FIFU-princippet. Hvad betyder det?`,
			en: ``,
		},
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1,
				text: {
					da: `Først ind først ud`,
					en: ``
				},
				isCorrect: true,
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							text: {
								da: `Ja! Placér sækkene, så de nyeste kommer ind bagved og bliver taget ud til sidst.`,
								en: ``,
							}
						})
					})
				]
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2,
				text: {
					da: `Fjern ikke fra udlevering`,
					en: ``
				}
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3,
				text: {
					da: `Først ind fjern ultimo`,
					en: ``
				}, 
			})
		]
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		taskId: 's3-m1-instructions-production-feed-pellets',
		text: {
			da: `Så skal vi i gang med at producere. Lad os kigge nærme på produktion af foderpiller.`,
			en: ``
		}
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 's3-m1-mc-feed-pellets-min-temp',
		text: {
			da: `Under pilletering skal der opnås en bestemt minimumstemperatur. Hvilken?`,
			en: ``,
		},
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1,
				text: {
					da: `83&deg; celsius`,
					en: ``
				},
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2,
				text: {
					da: `81&deg; celsius`,
					en: ``
				},
				isCorrect: true
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3,
				text: {
					da: `85&deg; celsius`,
					en: ``
				},
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4,
				text: {
					da: `82&deg; celsius`,
					en: ``
				},
			}),
		]
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 's3-m1-mc-feed-pellets-set-temp',
		layout: 's3-m1-feed-pellets-set-temp',
		subtype: 'images',
		text: {
			da: `Hvad skal du så sætte setpunktet til?`,
			en: ``,
		},
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1, // 82
				isCorrect: true,
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							text: {
								da: `Der er en usikkerhed på 1&deg; celsius. Ved at sætte setpunktet til 82&deg; celsius er du sikker på at nå minimumstemperaturen på 81&deg; celsius.`,
								en: ``,
							}
						})
					})
				]
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2, // 81
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3, // 85
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4, // 90
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 5, // 83
			}),
		]
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		taskId: 's3-m1-instructions-check-temp',
		text: {
			da: `Det er en god idé at tjekke om setpunktstemperaturen passer. Brug din kontroltemperaturføler til det.`,
			en: ``
		}
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 's3-m1-mc-cleaning-mix',
		text: {
			da: `Hvornår skal du køre en renseblanding?`,
			en: ``,
		},
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1,
				text: {
					da: `Mellem DK- og UK-blandinger`,
					en: ``
				},
				isCorrect: true,
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							text: {
								da: `Rigtigt! På Borris skal du også køre mellem GMO og non-GMO.`,
								en: ``,
							}
						})
					})
				]
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2,
				text: {
					da: `Mellem hver blanding`,
					en: ``
				},
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3,
				text: {
					da: `Mellem kvæg- og svineblanding`,
					en: ``
				},
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							text: {
								da: `I det tilfælde bruger man ikke renseblanding. Man renser i stedet ved at køre svineblanding uden animalsk biprodukt inden kvægblandingen.`,
								en: ``,
							}
						})
					})
				]
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4,
				text: {
					da: `Hver anden gang`,
					en: ``
				}
			})
		]
	}),
	Object.assign({}, taskTemplates.orderTemplate.mainTemplate, {
		taskId: 's3-m1-order-dosage-error',	
		text: {
			da: `Hvis der sker en fejl i doseringen, som først bliver opdaget når mineral- og/eller forbladingen ligger i blanderen, hvad skal der så gøres? Sæt handlingerne i den rigtige rækkefølge.`,
			en: ``,
		},
		items: [
			Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
				id: 1, 
				text: {
					da: `Led færdigvare over i separat silo`,
					en: ``
				},
				correctOrderNumbers: [1]
			}),
			Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
				id: 2, 
				text: {
					da: `Orientér produktchef`,
					en: ``
				},
				correctOrderNumbers: [2]
			}),
			Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
				id: 3, 
				text: {
					da: `Produktchef vurderer om varen kan genbruges`,
					en: ``
				},
				correctOrderNumbers: [3]
			}),
			Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
				id: 4, 
				text: {
					da: `Udfyld en F009 med alle de vigtige punkter`,
					en: ``
				},
				correctOrderNumbers: [4]
			}),
		]
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		taskId: 's3-m1-instructions-code-f009',
		text: {
			da: `Når du orienterer produktchefen, så brug koden "F009 Fejlmelding i produktionen".`,
			en: ``
		}
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		taskId: 's3-m1-instructions-delivery-hygiene',
		text: {
			da: `Hygiejne er også vigtigt i forhold til at udlevere varer.`,
			en: ``
		}
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 's3-m1-mc-contamination',
		text: {
			da: `Vores varer kan blive kontamineret under af- og pålæsning. Hvordan kan det ske?`,
			en: ``,
		},
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1,
				text: {
					da: `Hvis vognen ikke er rengjort fra tidligere læs`,
					en: ``
				},
				isCorrect: true,
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							text: {
								da: `Netop! Det er chaufførens ansvar, men ser du noget, der ikke er i orden, så skal du endelig reagere.`,
								en: ``,
							}
						})
					})
				]
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2,
				text: {
					da: `Hvis det er dårligt vejr`,
					en: ``
				},
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3,
				text: {
					da: `Hvis der er høje pollental`,
					en: ``
				},
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4,
				text: {
					da: `Hvis bilen kører på el`,
					en: ``
				},
			})
		]
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		taskId: 's3-m1-instructions-cleaning-and-control',
		text: {
			da: `De sidste opgaver handler om rengøring og kvalitetskontrol.`,
			en: ``
		}
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 's3-m1-mc-finished-product-sample',
		text: {
			da: `Hvor tit skal der udtages en færdigvareprøve?`,
			en: ``,
		},
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1,
				text: {
					da: `Hver hver 36 ton i større produktioner`,
					en: ``
				},
				isCorrect: true,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2,
				text: {
					da: `For hver produktion`,
					en: ``
				},
				isCorrect: true
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3,
				text: {
					da: `For hver 15 ton i større produktioner`,
					en: ``
				},
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4,
				text: {
					da: `Kun én prøve per produktion`,
					en: ``
				},
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 5,
				text: {
					da: `Hver anden uge`,
					en: ``
				},
			})
		]
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 's3-m1-mc-salmonella-ecoli',
		text: {
			da: `Hvad med prøver for salmonella og E-coli, hvor tit skal de tages?`,
			en: ``,
		},
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1,
				text: {
					da: `Minimum en gang om måneden`,
					en: ``
				},
				isCorrect: true,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2,
				text: {
					da: `Minimum en gang om ugen`,
					en: ``
				},
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3,
				text: {
					da: `Minimum en gang om dagen`,
					en: ``
				},
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4,
				text: {
					da: `Hver 14. dag`,
					en: ``
				},
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 5,
				text: {
					da: `Før hver produktion over 36 ton`,
					en: ``
				},
			})
		]
	}),
	Object.assign({}, taskTemplates.sortTemplate.mainTemplate, {
		taskId: 's3-m1-sort-areas',
		text: {
			da: `Du skal løbende kontrollere, om forskellige områder skal rengøres. Sorter områderne i de korrekte kasser.`,
			en: ``
		},
		categories: [
			Object.assign({}, taskTemplates.sortTemplate.categoryTemplate, {
				id: 1, title: {da: 'Ugentlig kontrol', en: ''}
			}),
			Object.assign({}, taskTemplates.sortTemplate.categoryTemplate, {
				id: 2, title: {da: 'Månedlig kontrol', en: ''}
			}),
			Object.assign({}, taskTemplates.sortTemplate.categoryTemplate, {
				id: 3, title: {da: 'Årlig kontrol', en: ''}
			}),
		],
		items: [
			Object.assign({}, taskTemplates.sortTemplate.itemTemplate, {
				id: 1, text: {da: 'Håndpåslag', en: ''}, categoryIds: [1]
			}),
			Object.assign({}, taskTemplates.sortTemplate.itemTemplate, {
				id: 2, text: {da: 'Fabriksgulv', en: ''}, categoryIds: [1]
			}),
			Object.assign({}, taskTemplates.sortTemplate.itemTemplate, {
				id: 3, text: {da: 'Vægte', en: ''}, categoryIds: [1]
			}),
			Object.assign({}, taskTemplates.sortTemplate.itemTemplate, {
				id: 4, text: {da: 'Forbeholder', en: ''}, categoryIds: [2],
			}),
			Object.assign({}, taskTemplates.sortTemplate.itemTemplate, {
				id: 5, text: {da: 'Blander', en: ''}, categoryIds: [2]
			}),
			Object.assign({}, taskTemplates.sortTemplate.itemTemplate, {
				id: 6, text: {da: 'Operatørrum', en: ''}, categoryIds: [1],
			})
		]
	}),
	Object.assign({}, taskTemplates.sortTemplate.mainTemplate, {
		taskId: 's3-m1-sort-areas-2',
		text: {
			da: `Hvad med disse områder - hvor ofte skal de kontrolleres for rengøring?`,
			en: ``
		},
		categories: [
			Object.assign({}, taskTemplates.sortTemplate.categoryTemplate, {
				id: 1, title: {da: 'Daglig kontrol', en: ''}
			}),
			Object.assign({}, taskTemplates.sortTemplate.categoryTemplate, {
				id: 2, title: {da: 'Ugentlig kontrol', en: ''}
			}),
			Object.assign({}, taskTemplates.sortTemplate.categoryTemplate, {
				id: 3, title: {da: 'Månedlig kontrol', en: ''}
			}),

		],
		items: [
			Object.assign({}, taskTemplates.sortTemplate.itemTemplate, {
				id: 1, text: {da: 'Påslag', en: ''}, categoryIds: [1]
			}),
			Object.assign({}, taskTemplates.sortTemplate.itemTemplate, {
				id: 2, text: {da: 'Køler', en: ''}, categoryIds: [1]
			}),
			Object.assign({}, taskTemplates.sortTemplate.itemTemplate, {
				id: 3, text: {da: 'Nedløb', en: ''}, categoryIds: [1]
			}),
			Object.assign({}, taskTemplates.sortTemplate.itemTemplate, {
				id: 4, text: {da: 'Trapper', en: ''}, categoryIds: [2],
			}),
			Object.assign({}, taskTemplates.sortTemplate.itemTemplate, {
				id: 5, text: {da: 'Operatørrum', en: ''}, categoryIds: [2]
			}),
			Object.assign({}, taskTemplates.sortTemplate.itemTemplate, {
				id: 6, text: {da: 'Container', en: ''}, categoryIds: [3],
			}),
			Object.assign({}, taskTemplates.sortTemplate.itemTemplate, {
				id: 7, text: {da: 'Efterbeholder', en: ''}, categoryIds: [3],
			})
		]
	}),
	Object.assign({}, taskTemplates.spotErrorsTemplate.mainTemplate, {
		taskId: 's3-m1-spot-errors-production',
		layout: 's1-m1-storage-2',
		text: {
			da: `Se om du kan spotte de 3 problemer her. Tryk OK, når du er klar, eller hvis du ikke kan finde dem alle.`,
			en: ``,
		},
		errors: [
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {
				id: 'pidgeon',
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							text: {
								da: `Ja, ud med den. Den er sikkert fløjet ind af en port, der har stået åben.`,
								en: ``,
							}
						})
					})
				]
			}),
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {
				id: 'moth',
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							text: {
								da: `Godt set! Hvis der er møl i mølfælderne, så skal en skadedyrsbekæmper tilkaldes.`,
								en: ``,
							}
						})
					})
				]
			}),
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {
				id: 'gate',
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							text: {
								da: `Godt fanget! Hvis vi husker at lukke porte, der ikke er i brug, så er det sværere for skadedyr at komme ind.`,
								en: ``,
							}
						})
					})
				]
			}),
		]
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		taskId: 's3-m1-instructions-animals',
		text: {
			da: `Gnavere, fugle og insekter kan forurene vores varer. Derfor er det vigtigt at holde øje med spor efter dem.`,
			en: ``
		}
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		taskId: 's3-m1-instructions-surveys',
		characters: ['manager pose-2 coat-2'],
		text: {
			da: `Til allersidst er der fire spørgsmål om fødevaresikkerhedskulturen på VJA. Her er der ikke rigtige og forkerte svar. Spørgsmålene giver ikke point, og besvarelserne er anonyme.`,
			en: ``
		}
	}),
	Object.assign({}, taskTemplates.surveyTemplate.mainTemplate, {
		taskId: 's3-m1-survey-1',
		shuffleOptions: false,
		text: {
			da: `<b>Hvor enig er du i følgende:</b>
				<br /><br />Jeg må og skal reagere, hvis jeg ser, at der er risiko for fødevaresikkerheden?`,
			en: ``
		},
		options: [
			Object.assign({}, taskTemplates.surveyTemplate.optionTemplate, {
				id: 1, text: {da: `Meget enig`, en: `Strongly agree`}
			}),
			Object.assign({}, taskTemplates.surveyTemplate.optionTemplate, {
				id: 2, text: {da: `Enig`, en: `Agree`}
			}),
			Object.assign({}, taskTemplates.surveyTemplate.optionTemplate, {
				id: 3, text: {da: `Hverken enig eller uenig`, en: `Neither agree nor disagree`}
			}),
			Object.assign({}, taskTemplates.surveyTemplate.optionTemplate, {
				id: 4, text: {da: `Uenig`, en: `Disagree`}
			}),
			Object.assign({}, taskTemplates.surveyTemplate.optionTemplate, {
				id: 5, text: {da: `Meget uenig`, en: `Strongly disagree`}
			}),
			Object.assign({}, taskTemplates.surveyTemplate.optionTemplate, {
				id: 6, text: {da: `Ved ikke`, en: `Don't know`}
			}),
		]
	}),
	Object.assign({}, taskTemplates.surveyTemplate.mainTemplate, {
		taskId: 's3-m1-survey-2',
		shuffleOptions: false,
		text: {
			da: `<b>Hvor enig er du i følgende:</b>
				<br /><br />Jeg reagerer hvis jeg ser andre, der ikke følger hygiejnereglerne?`,
			en: ``
		},
		options: [
			Object.assign({}, taskTemplates.surveyTemplate.optionTemplate, {
				id: 1, text: {da: `Meget enig`, en: `Strongly agree`}
			}),
			Object.assign({}, taskTemplates.surveyTemplate.optionTemplate, {
				id: 2, text: {da: `Enig`, en: `Agree`}
			}),
			Object.assign({}, taskTemplates.surveyTemplate.optionTemplate, {
				id: 3, text: {da: `Hverken enig eller uenig`, en: `Neither agree nor disagree`}
			}),
			Object.assign({}, taskTemplates.surveyTemplate.optionTemplate, {
				id: 4, text: {da: `Uenig`, en: `Disagree`}
			}),
			Object.assign({}, taskTemplates.surveyTemplate.optionTemplate, {
				id: 5, text: {da: `Meget uenig`, en: `Strongly disagree`}
			}),
			Object.assign({}, taskTemplates.surveyTemplate.optionTemplate, {
				id: 6, text: {da: `Ved ikke`, en: `Don't know`}
			}),
		]
	}),
	Object.assign({}, taskTemplates.surveyTemplate.mainTemplate, {
		taskId: 's3-m1-survey-3',
		shuffleOptions: false,
		text: {
			da: `<b>Hvor enig er du i følgende:</b>
				<br /><br />Hvis jeg ser et fødevaresikkerhedsproblem, så løser jeg det eller informerer min nærmeste leder?`,
			en: ``
		},
		options: [
			Object.assign({}, taskTemplates.surveyTemplate.optionTemplate, {
				id: 1, text: {da: `Meget enig`, en: `Strongly agree`}
			}),
			Object.assign({}, taskTemplates.surveyTemplate.optionTemplate, {
				id: 2, text: {da: `Enig`, en: `Agree`}
			}),
			Object.assign({}, taskTemplates.surveyTemplate.optionTemplate, {
				id: 3, text: {da: `Hverken enig eller uenig`, en: `Neither agree nor disagree`}
			}),
			Object.assign({}, taskTemplates.surveyTemplate.optionTemplate, {
				id: 4, text: {da: `Uenig`, en: `Disagree`}
			}),
			Object.assign({}, taskTemplates.surveyTemplate.optionTemplate, {
				id: 5, text: {da: `Meget uenig`, en: `Strongly disagree`}
			}),
			Object.assign({}, taskTemplates.surveyTemplate.optionTemplate, {
				id: 6, text: {da: `Ved ikke`, en: `Don't know`}
			}),
		]
	}),
	Object.assign({}, taskTemplates.surveyTemplate.mainTemplate, {
		taskId: 's3-m1-survey-4',
		shuffleOptions: false,
		text: {
			da: `<b>Hvor enig er du i følgende:</b>
				<br /><br />Hvis jeg oplyser om en risiko for fødevaresikkerheden, så bliver der gjort noget ved det?`,
			en: ``
		},
		options: [
			Object.assign({}, taskTemplates.surveyTemplate.optionTemplate, {
				id: 1, text: {da: `Meget enig`, en: `Strongly agree`}
			}),
			Object.assign({}, taskTemplates.surveyTemplate.optionTemplate, {
				id: 2, text: {da: `Enig`, en: `Agree`}
			}),
			Object.assign({}, taskTemplates.surveyTemplate.optionTemplate, {
				id: 3, text: {da: `Hverken enig eller uenig`, en: `Neither agree nor disagree`}
			}),
			Object.assign({}, taskTemplates.surveyTemplate.optionTemplate, {
				id: 4, text: {da: `Uenig`, en: `Disagree`}
			}),
			Object.assign({}, taskTemplates.surveyTemplate.optionTemplate, {
				id: 5, text: {da: `Meget uenig`, en: `Strongly disagree`}
			}),
			Object.assign({}, taskTemplates.surveyTemplate.optionTemplate, {
				id: 6, text: {da: `Ved ikke`, en: `Don't know`}
			}),
		]
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		taskId: 's3-m1-instructions-done',
		text: {
			da: `Sådan, du er nu igennem det her modul. Hvis du har fået tre stjerner, er du færdig med træningen. Hvis ikke, så må du tage modulet en gang til.`,
			en: ``
		}
	}),
];

let taskIdsArr = [];
moduleTasks.forEach((task, index) => {
	task.id = 's3-m1-' + (index + 1 < 10 ? '0' : '') + (index + 1);
	
	/* Check taskId is set when required and unique */
	if (task.isSolveToContinue) {
		if (task.taskId) {
			if (taskIdsArr.indexOf(task.taskId) >= 0) {
				console.error('Task id not unique: ', task.taskId);
			} else {
				taskIdsArr.push(task.taskId);
			}
		} else {
			console.error('Missing task id for task: ', task);
		}
	}
});


export {
	moduleTasks
};