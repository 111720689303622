const backgroundsData = [
	{
		id: 'outside',
		modifiers: [
			{
				id: 'top',
			},
			{
				id: 'bottom'
			}
		],
	},
	{
		id: 'production',
		modifiers: [
			{
				id: 'none'
			},
			{
				id: 'no-lamp'
			}
		]
	},
	{
		id: 'facilitator',
		modifiers: []
	}
];

export {backgroundsData};