import {scenariosData} from 'data/scenarios-data';
import {loginUiTexts} from 'data/ui-texts/login-ui-texts';
import {adminUiTexts} from 'data/ui-texts/admin-ui-texts';
import {facilitatorUiTexts} from 'data/ui-texts/facilitator-ui-texts';
import {getText} from './language-helper';


function getGamesColumns(languageId) {
	const gamesColumns = [
		{value: 'gameName', label: getText(loginUiTexts.name, languageId)},
		{value: 'created', label: getText(adminUiTexts.created, languageId)},
		{value: 'scenario', label: getText(adminUiTexts.scenario, languageId)},
		{value: 'facilitators', label: getText(adminUiTexts.facilitators, languageId)},
		{value: 'players', label: getText(adminUiTexts.players, languageId)},
		{value: 'url', label: getText(adminUiTexts.gameUrl, languageId)}
	];
	return gamesColumns;
}

function getGeneralStatsColumns(languageId) {
	const generalStatsColumns = [
		{value: 'moduleGroup', label: getText(adminUiTexts.gameStatsPopup.moduleGroup, languageId)},
		{value: 'players', label: getText(adminUiTexts.gameStatsPopup.numberOfPlayers, languageId)},
		{
			value: 'timeSpent', 
			label: getText(adminUiTexts.gameStatsPopup.timeSpent, languageId) + 
				' (' + getText(adminUiTexts.gameStatsPopup.minutes, languageId) + ')'
		},
		{value: 'errors', label: getText(adminUiTexts.gameStatsPopup.numberOfErrors, languageId)},
		{value: 'completed', label: getText(adminUiTexts.gameStatsPopup.completed, languageId)},
	];
	return generalStatsColumns;
}

function getModuleStatsColumns(languageId) {
	const moduleStatsColumns = [
		{value: 'task', label: getText(adminUiTexts.gameStatsPopup.task, languageId)},
		{value: 'type', label: getText(adminUiTexts.gameStatsPopup.type, languageId)},
		{value: 'mistakes', label: getText(adminUiTexts.gameStatsPopup.mistakes, languageId)},
		{value: 'perfectAttempts', label: getText(adminUiTexts.gameStatsPopup.perfectAttempts, languageId)},
		{value: 'attempts', label: getText(adminUiTexts.gameStatsPopup.attempts, languageId)},
	];
	return moduleStatsColumns;
}

export function getStatsSheets(languageId) {
	let sheets = [
		{
			id: 'games', 
			name: getText(adminUiTexts.gameStatsDownload.games, languageId), 
			columns: getGamesColumns(languageId), 
			data: []
		}, 
		{
			id: 'general', 
			name: getText(adminUiTexts.gameStatsDownload.generalStats, languageId), 
			columns: getGeneralStatsColumns(languageId), 
			data: []
		},
	];

	scenariosData.forEach((scenarioData) => {
		scenarioData.modulesData.forEach((moduleData) => {
			sheets.push({
				id: moduleData.id,
				name: getText(moduleData.title, languageId),
				columns: getModuleStatsColumns(languageId),
				data: []
			});
		});
	});
	
	
	return sheets;
}

export function getPlayerStatsColumns(languageId, scenarioId) {
	const playerStatsColumns = [
		{value: 'email', label: getText(loginUiTexts.email, languageId)},
		{value: 'name', label: getText(loginUiTexts.name, languageId)},
		{value: 'created', label: getText(facilitatorUiTexts.created, languageId)},
		{value: 'lastLogin', label: getText(facilitatorUiTexts.lastLogin, languageId)},
	];
	const scenarioData = scenariosData.find((s) => {return s.id === scenarioId;});
	if (scenarioData && scenarioData.moduleGroups && scenarioData.moduleGroups.length > 0) {
		
		scenarioData.moduleGroups.forEach((moduleGroup) => {
			playerStatsColumns.push({
				value: 'group-' + moduleGroup.id,
				label: getText(moduleGroup.title, languageId)
			});
		});
	}
	playerStatsColumns.push({
		value: 'lastCompletedModule',
		label: getText(facilitatorUiTexts.lastCompletedModule, languageId)
	});
	return playerStatsColumns;
}


export function getFacilitatorStatsSheets(languageId, scenarioId) {
	const sheets = [
		{
			id: 'players', 
			name: getText(facilitatorUiTexts.players, languageId), 
			columns: getPlayerStatsColumns(languageId, scenarioId), 
			data: []
		}
	];

	return sheets;
}