const taskTemplates = require('data/templates/task-templates');
const {effectTemplate} = require('data/templates/effect-template');

let moduleTasks = [
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		taskId: 's2-m1-instructions-welcome',
		showProgressBar: false,
		background: 'outside',
		backgroundModifier: 'bottom',
		text: {
			da: `Velkommen til hygiejnetræningen for chauffører. Jeg er din teamleder og hjælper dig gennem spillet.`,
			en: ``,
		}
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		taskId: 's2-m1-instructions-points',
		showProgressBar: false,
		background: 'outside',
		backgroundModifier: 'bottom',
		text: {
			da: `I spillet får du point for at løse opgaver. Jo færre fejl du laver, jo flere point får du.`,
			en: ``
		}
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		taskId: 's2-m1-instructions-stars',
		background: 'outside',
		backgroundModifier: 'bottom',
		characters: ['manager pose-2 coat-2'],
		text: {
			da: `Når du får point, kan du også få stjerner. I toppen af skærmen kan du se, hvor mange stjerner og point du har.`,
			en: ``
		}
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		taskId: 's2-m1-instructions-welcome-three-stars',
		background: 'outside',
		backgroundModifier: 'bottom',
		text: {
			da: `Spillet handler om, hvordan vi undgår, at vores foderstoffer bliver forurenet.`,
			en: ``
		}
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 's2-m1-mc-why-contamination-problem',
		background: 'outside',
		backgroundModifier: 'bottom',
		text: {
			da: `Forurening kan være snavs, regnvand, efterladenskaber fra skadedyr osv. Men det kan også være krydsforurening. Det vil sige, at rester af en vare findes i en anden. Hvorfor er forurening et problem?`,
			en: ``,
		},
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1,
				text: {
					da: `Det kan være farligt`,
					en: ``
				},
				isCorrect: true
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2,
				text: {
					da: `Det kan gøre forbrugerne syge`,
					en: ``
				},
				isCorrect: true
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3,
				text: {
					da: `Det er ulovligt`,
					en: ``
				},
				isCorrect: true
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4,
				text: {
					da: `Det udleder mere CO2`,
					en: ``
				},
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 5,
				text: {
					da: `Det kan gøre vores tøj beskidt`,
					en: ``
				},
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 6,
				text: {
					da: `Forurening er kun et problem hvis Kvalitet ser det`,
					en: ``
				},
			})
		]
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		taskId: 's2-m1-instructions-driving-list',
		background: 'outside',
		backgroundModifier: 'bottom',
		text: {
			da: `Når du får en køreliste, skal du tage stilling til, hvor du skal hen, og om der skal køres med for-vogn eller anhænger.`,
			en: ``
		}
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 's2-m1-mc-three-last-loads',
		background: 'outside',
		backgroundModifier: 'bottom',
		text: {
			da: `Du skal også kigge i logbogen og se, hvad de tre sidste læs har været. Hvorfor er det vigtigt?`,
			en: ``,
		},
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1,
				text: {
					da: `Fordi det afgør hvordan vognen skal rengøres`,
					en: ``
				},
				isCorrect: true,
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							text: {
								da: `Netop!`,
								en: ``,
							}
						})
					})
				]
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2,
				text: {
					da: `Fordi det afgør hvad jeg må køre med`,
					en: ``
				},
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3,
				text: {
					da: `Fordi det afgør, om jeg skal bruge hænger`,
					en: ``
				},
			}),
		]
	}),
	Object.assign({}, taskTemplates.matchTemplate.mainTemplate, {
		taskId: 's2-m1-match-abcd',
		background: 'outside',
		backgroundModifier: 'bottom',
		text: {
			da: `Hvad er ABCD-rengøring? Match rengøring og bogstav.`,
			en: ``,
		},
		categories: [
			Object.assign({}, taskTemplates.matchTemplate.categoryTemplate, {id: 1, text: {da: 'A', en: 'A'}, itemIds: [1]}),
			Object.assign({}, taskTemplates.matchTemplate.categoryTemplate, {id: 2, text: {da: 'B', en: 'B'}, itemIds: [2]}),
			Object.assign({}, taskTemplates.matchTemplate.categoryTemplate, {id: 3, text: {da: 'C', en: 'C'}, itemIds: [3]}),
			Object.assign({}, taskTemplates.matchTemplate.categoryTemplate, {id: 4, text: {da: 'D', en: 'D'}, itemIds: [4]}),
		],
		items: [
			Object.assign({}, taskTemplates.matchTemplate.itemTemplate, {
				id: 1, 
				text: {
					da: 'Fejning/afblæsning',
					en: ''
				}
			}),
			Object.assign({}, taskTemplates.matchTemplate.itemTemplate, {
				id: 2, 
				text: {
					da: 'Vask med vand',
					en: ''
				}
			}),
			Object.assign({}, taskTemplates.matchTemplate.itemTemplate, {
				id: 3, 
				text: {
					da: 'Vask med sæbe og vand',
					en: ''
				}
			}),
			Object.assign({}, taskTemplates.matchTemplate.itemTemplate, {
				id: 4, 
				text: {
					da: 'Vask med sæbe, vand og desinfektion',
					en: ''
				}
			}),
		]
	}),
	Object.assign({}, taskTemplates.orderTemplate.mainTemplate, {
		taskId: 's2-m1-order-fish',	
		background: 'outside',
		backgroundModifier: 'bottom',	
		text: {
			da: `Hvis du har kørt med fiskemel eller fiske- eller blodmelsholdige foderblandinger, hvad skal du så gøre? Sæt handlingerne i den rigtige rækkefølge. Tryk OK, når du er klar.`,
			en: ``,
		},
		items: [
			Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
				id: 1,
				text: {
					da: `Tomblæs anlægget`,
					en: ``
				},
				correctOrderNumbers: [1]
			}),
			Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
				id: 2,
				text: {
					da: `Rengør filtre og poser`,
					en: ``,
				},
				correctOrderNumbers: [2]
			}),
			Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
				id: 3,
				text: {
					da: `Åbn lemme og fjern eventuelle rester`,
					en: ``,
				},
				correctOrderNumbers: [3]
			}),
			Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
				id: 4,
				text: {
					da: `Noter i logbog`,
					en: ``,
				},
				correctOrderNumbers: [4]
			}),
		]
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 's2-m1-mc-fish-gmo-eco',
		background: 'outside',
		backgroundModifier: 'bottom',
		text: {
			da: `Hvis du har kørt med en blanding indeholdende fiskemel i foregående læs, må du så køre med non-GMO eller øko?`,
			en: ``,
		},
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1,
				text: {
					da: `Nej`,
					en: ``
				},
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2,
				text: {
					da: `Ja`,
					en: ``
				},
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3,
				text: {
					da: `Kun hvis jeg laver B-rengøring`,
					en: ``
				},
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4,
				text: {
					da: `Kun hvis jeg laver C-rengøring`,
					en: ``
				},
				isCorrect: true,
			}),
		]
	}),
	Object.assign({}, taskTemplates.spotErrorsTemplate.mainTemplate, {
		taskId: 's2-m1-spot-errors-truck',
		layout: 's2-m1-truck',
		background: 'outside',
		backgroundModifier: 'bottom',
		text: {
			da: `Prøv at se om du kan spotte de 4 problemer på det her billede. Tryk OK, når du har dem, eller hvis du ikke kan finde dem alle.`,
			en: ``,
		},
		errors: [
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {id: 'hole'}),
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {id: 'mould'}),
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {id: 'dirt'}),
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {id: 'cabin'}),
		]
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		taskId: 's2-m1-instructions-deliver-goods',
		background: 'outside',
		backgroundModifier: 'bottom',
		text: {
			da: `Næste skridt er at aflevere varerne til kunderne.`,
			en: ``
		}
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 's2-m1-mc-delivery-attention',
		background: 'outside',
		backgroundModifier: 'bottom',
		text: {
			da: `Hvad skal du være særligt opmærksom på, når du afleverer til kunder?`,
			en: ``,
		},
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1,
				text: {
					da: `Om kunden ser glad ud`,
					en: ``
				},
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							text: {
								da: `Vi kan godt lide glade kunder. Men du møder som regel slet ikke kunden, så der er ingen grund til at gå rundt og lede.`,
								en: ``,
							}
						})
					})
				]
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2,
				text: {
					da: `Om aflæsningssted er beskidt`,
					en: ``
				},
				isCorrect: true
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3,
				text: {
					da: `Om der ligger døde dyr ved siden af indblæsning`,
					en: ``
				},
				isCorrect: true
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4,
				text: {
					da: `At tage billeder af dårlig forhold`,
					en: ``
				},
				isCorrect: true,
			}),
		]
	}),
	Object.assign({}, taskTemplates.orderTemplate.mainTemplate, {
		taskId: 's2-m1-order-customer-delivery',		
		background: 'outside',
		backgroundModifier: 'bottom',
		text: {
			da: `Hvad er den korrekte rækkefølge at gøre tingene i, når du afleverer til kunder?`,
			en: ``,
		},
		items: [
			Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
				id: 1,
				text: {
					da: `Vurder adgangsforhold (fx vejr)`,
					en: ``
				},
				correctOrderNumbers: [1]
			}),
			Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
				id: 2,
				text: {
					da: `Kontroller følgeseddel med aflæsningssted`,
					en: ``,
				},
				correctOrderNumbers: [2]
			}),
			Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
				id: 3,
				text: {
					da: `Vurder hygiejneforhold ved indblæsning`,
					en: ``,
				},
				correctOrderNumbers: [3]
			}),
			Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
				id: 4,
				text: {
					da: `Indblæs varer`,
					en: ``,
				},
				correctOrderNumbers: [4]
			}),
		]
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		taskId: 's2-m1-instructions-control-cleaning',
		background: 'outside',
		backgroundModifier: 'bottom',
		text: {
			da: `Så mangler der kun et par spørgsmål om kontrol og rengøring.`,
			en: ``
		}
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 's2-m1-mc-salmonella',
		text: {
			da: `Hvor ofte skal der laves en prøve til salmonella-test?`,
			en: ``,
		},
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1,
				text: {
					da: `Hvert kvartal`,
					en: ``
				},
				isCorrect: true
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2,
				text: {
					da: `Halvårligt`,
					en: ``
				},
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3,
				text: {
					da: `Hver måned`,
					en: ``
				},
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4,
				text: {
					da: `Årligt`,
					en: ``
				}
			})
		]
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 's2-m1-mc-blue-containers',
		text: {
			da: `Hvad med lastrummet, hvor ofte skal det desinficeres?`,
			en: ``,
		},
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1,
				text: {
					da: `Efter behov`,
					en: ``
				},
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2,
				text: {
					da: `Årligt`,
					en: ``
				},
				isCorrect: true,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3,
				text: {
					da: `Halvårligt`,
					en: ``
				},
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4,
				text: {
					da: `Hver måned`,
					en: ``
				},
			})
		]
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		taskId: 's2-m1-instructions-surveys',
		characters: ['manager pose-2 coat-2'],
		text: {
			da: `Til allersidst er der fire spørgsmål om fødevaresikkerhedskulturen på VJA. Her er der ikke rigtige og forkerte svar. Spørgsmålene giver ikke point, og besvarelserne er anonyme.`,
			en: ``
		}
	}),
	Object.assign({}, taskTemplates.surveyTemplate.mainTemplate, {
		taskId: 's2-m1-survey-1',
		shuffleOptions: false,
		text: {
			da: `<b>Hvor enig er du i følgende:</b>
				<br /><br />Jeg må og skal reagere, hvis jeg ser, at der er risiko for fødevaresikkerheden?`,
			en: ``
		},
		options: [
			Object.assign({}, taskTemplates.surveyTemplate.optionTemplate, {
				id: 1, text: {da: `Meget enig`, en: `Strongly agree`}
			}),
			Object.assign({}, taskTemplates.surveyTemplate.optionTemplate, {
				id: 2, text: {da: `Enig`, en: `Agree`}
			}),
			Object.assign({}, taskTemplates.surveyTemplate.optionTemplate, {
				id: 3, text: {da: `Hverken enig eller uenig`, en: `Neither agree nor disagree`}
			}),
			Object.assign({}, taskTemplates.surveyTemplate.optionTemplate, {
				id: 4, text: {da: `Uenig`, en: `Disagree`}
			}),
			Object.assign({}, taskTemplates.surveyTemplate.optionTemplate, {
				id: 5, text: {da: `Meget uenig`, en: `Strongly disagree`}
			}),
			Object.assign({}, taskTemplates.surveyTemplate.optionTemplate, {
				id: 6, text: {da: `Ved ikke`, en: `Don't know`}
			}),
		]
	}),
	Object.assign({}, taskTemplates.surveyTemplate.mainTemplate, {
		taskId: 's2-m1-survey-2',
		shuffleOptions: false,
		text: {
			da: `<b>Hvor enig er du i følgende:</b>
				<br /><br />Jeg reagerer hvis jeg ser andre, der ikke følger hygiejnereglerne?`,
			en: ``
		},
		options: [
			Object.assign({}, taskTemplates.surveyTemplate.optionTemplate, {
				id: 1, text: {da: `Meget enig`, en: `Strongly agree`}
			}),
			Object.assign({}, taskTemplates.surveyTemplate.optionTemplate, {
				id: 2, text: {da: `Enig`, en: `Agree`}
			}),
			Object.assign({}, taskTemplates.surveyTemplate.optionTemplate, {
				id: 3, text: {da: `Hverken enig eller uenig`, en: `Neither agree nor disagree`}
			}),
			Object.assign({}, taskTemplates.surveyTemplate.optionTemplate, {
				id: 4, text: {da: `Uenig`, en: `Disagree`}
			}),
			Object.assign({}, taskTemplates.surveyTemplate.optionTemplate, {
				id: 5, text: {da: `Meget uenig`, en: `Strongly disagree`}
			}),
			Object.assign({}, taskTemplates.surveyTemplate.optionTemplate, {
				id: 6, text: {da: `Ved ikke`, en: `Don't know`}
			}),
		]
	}),
	Object.assign({}, taskTemplates.surveyTemplate.mainTemplate, {
		taskId: 's2-m1-survey-3',
		shuffleOptions: false,
		text: {
			da: `<b>Hvor enig er du i følgende:</b>
				<br /><br />Hvis jeg ser et fødevaresikkerhedsproblem, så løser jeg det eller informerer min nærmeste leder?`,
			en: ``
		},
		options: [
			Object.assign({}, taskTemplates.surveyTemplate.optionTemplate, {
				id: 1, text: {da: `Meget enig`, en: `Strongly agree`}
			}),
			Object.assign({}, taskTemplates.surveyTemplate.optionTemplate, {
				id: 2, text: {da: `Enig`, en: `Agree`}
			}),
			Object.assign({}, taskTemplates.surveyTemplate.optionTemplate, {
				id: 3, text: {da: `Hverken enig eller uenig`, en: `Neither agree nor disagree`}
			}),
			Object.assign({}, taskTemplates.surveyTemplate.optionTemplate, {
				id: 4, text: {da: `Uenig`, en: `Disagree`}
			}),
			Object.assign({}, taskTemplates.surveyTemplate.optionTemplate, {
				id: 5, text: {da: `Meget uenig`, en: `Strongly disagree`}
			}),
			Object.assign({}, taskTemplates.surveyTemplate.optionTemplate, {
				id: 6, text: {da: `Ved ikke`, en: `Don't know`}
			}),
		]
	}),
	Object.assign({}, taskTemplates.surveyTemplate.mainTemplate, {
		taskId: 's2-m1-survey-4',
		shuffleOptions: false,
		text: {
			da: `<b>Hvor enig er du i følgende:</b>
				<br /><br />Hvis jeg oplyser om en risiko for fødevaresikkerheden, så bliver der gjort noget ved det?`,
			en: ``
		},
		options: [
			Object.assign({}, taskTemplates.surveyTemplate.optionTemplate, {
				id: 1, text: {da: `Meget enig`, en: `Strongly agree`}
			}),
			Object.assign({}, taskTemplates.surveyTemplate.optionTemplate, {
				id: 2, text: {da: `Enig`, en: `Agree`}
			}),
			Object.assign({}, taskTemplates.surveyTemplate.optionTemplate, {
				id: 3, text: {da: `Hverken enig eller uenig`, en: `Neither agree nor disagree`}
			}),
			Object.assign({}, taskTemplates.surveyTemplate.optionTemplate, {
				id: 4, text: {da: `Uenig`, en: `Disagree`}
			}),
			Object.assign({}, taskTemplates.surveyTemplate.optionTemplate, {
				id: 5, text: {da: `Meget uenig`, en: `Strongly disagree`}
			}),
			Object.assign({}, taskTemplates.surveyTemplate.optionTemplate, {
				id: 6, text: {da: `Ved ikke`, en: `Don't know`}
			}),
		]
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		taskId: 's2-m1-instructions-done',
		text: {
			da: `Sådan, du er nu igennem det her modul. Hvis du har fået tre stjerner, er du færdig med træningen. Hvis ikke, så må du tage modulet en gang til.`,
			en: ``
		}
	}),
];

let taskIdsArr = [];
moduleTasks.forEach((task, index) => {
	task.id = 's2-m1-' + (index + 1 < 10 ? '0' : '') + (index + 1);
	
	/* Check taskId is set when required and unique */
	if (task.isSolveToContinue) {
		if (task.taskId) {
			if (taskIdsArr.indexOf(task.taskId) >= 0) {
				console.error('Task id not unique: ', task.taskId);
			} else {
				taskIdsArr.push(task.taskId);
			}
		} else {
			console.error('Missing task id for task: ', task);
		}
	}
});


export {
	moduleTasks
};