import {scenariosData} from 'data/scenarios-data';
import {playerUiTexts} from 'data/ui-texts/player-ui-texts';
import {getText} from './language-helper';
/**
 * Get texts of task
 * @param {object} taskData 
 * @param {string} languageId 
 * @returns 
 */
const getTextsFromTask = (taskData, languageId) => {
	const texts = [];
	
	/* Get basic text */
	if (taskData.text && taskData.text.hasOwnProperty(languageId)) {
		let text = taskData.text[languageId]
			.replace(/<br \/>/g, '')
			.replace(/<\/b>/g, '')
			.replace(/<b>/g, '')
			.replace(/&deg;/g, '')
			.replace(/non-GMO/g, 'non G M O')
			.replace(/mug/g, 'måk')
		;

		if (
			taskData.type === 'multiple-choice' && 
			taskData.showRequiredNumberOfAnswers &&
			taskData.options.length > 0
		) {
			const numberOfCorrectOptions = taskData.options.filter((o) => {
				return o.isCorrect === true;
			}).length;
			if (numberOfCorrectOptions > 0) {
				text += ' ' + getText(playerUiTexts.choose, languageId) + ' ' + numberOfCorrectOptions;
			}
		}

		if (
			taskData.type === 'survey' &&
			taskData.options.length > 0
		) {
			/* Get min/max number of options to select */
			const minOptionsToSelect = taskData.minOptionsToSelect;
			const maxOptionsToSelect = taskData.maxOptionsToSelect;

			/* Select x options text */
			let selectOptionsText = null;
			if (taskData.showRequiredNumberOfAnswers) {
				if (minOptionsToSelect === maxOptionsToSelect) {
					selectOptionsText = JSON.parse(JSON.stringify(
						getText(playerUiTexts.surveyChooseXOptions, languageId)))
						.replace('%X%', maxOptionsToSelect) + ':';
				} else {
					selectOptionsText = JSON.parse(JSON.stringify(
						getText(playerUiTexts.surveyChooseMaxXOptions, languageId)))
						.replace('%X%', maxOptionsToSelect) + ':';
				}
			}

			text += selectOptionsText;
		}

		texts.push({
			id: languageId + '-' + taskData.taskId,
			text: text
		});	
	} else if (taskData.introText && taskData.introText.hasOwnProperty(languageId)) {
		const text = taskData.introText[languageId].replace(/<br \/>/g, '').replace(/&deg;/g, '');

		texts.push({
			id: languageId + '-' + taskData.taskId,
			text: text
		});	
	}

	/* Get multiple choice / survey options & feedback texts and dialogue step texts */
	if (taskData.type === 'multiple-choice' || taskData.type === 'survey') {
		if (taskData.options && taskData.options.length > 0) {
			taskData.options.forEach((option) => {
				if (option.text && option.text.hasOwnProperty(languageId)) {
					texts.push({
						id: languageId + '-' + taskData.taskId + '-' + option.id,
						text: option.text[languageId]
							.replace(/<br \/>/g, '')
							.replace(/&deg;/g, '')
							.replace(/VLOG/g, 'V log')
					});
				}
				if (option.effects && option.effects.length > 0) {
					option.effects.forEach((effect, effectIndex) => {
						if (
							effect.type === 'feedback' && 
							effect.feedback &&
							effect.feedback.text &&
							effect.feedback.text.hasOwnProperty(languageId)
						) {
							texts.push({
								id: languageId + '-' + taskData.taskId + '-' 
									+ option.id + '-feedback-' + (effectIndex + 1),
								text: effect.feedback.text[languageId]
									.replace(/<br \/>/g, '')
									.replace(/&deg;/g, '')
									.replace(/non-GMO/g, 'non G M O')
									.replace(/Mug/g, 'måk')
							});
						}
					});
				}
			});
		}
	}

	/* Get sort/order/match item texts */
	if (taskData.type === 'sort' || taskData.type === 'order' || taskData.type === 'match') {
		if (taskData.items && taskData.items.length > 0) {
			taskData.items.forEach((item) => {
				if (item.text && item.text.hasOwnProperty(languageId)) {
					texts.push({
						id: languageId + '-' + taskData.taskId + '-' + item.id,
						text: item.text[languageId].replace(/<br \/>/g, '').replace(/&deg;/g, '')
					});
				}
			});
		}
	}

	/* Get match category titles */
	if (taskData.type === 'match') {
		if (taskData.categories && taskData.categories.length > 0) {
			taskData.categories.forEach((category) => {
				if (category.text && category.text.hasOwnProperty(languageId)) {
					texts.push({
						id: languageId + '-' + taskData.taskId + '-cat-' + category.id,
						text: category.text[languageId].replace(/<br \/>/g, '').replace(/&deg;/g, '')
					});
				}
			});
		}
	}

	/* Get dialogue step texts and step options texts */
	if (taskData.type === 'dialogue') {
		if (taskData.steps && taskData.steps.length > 0) {
			taskData.steps.forEach((step) => {
				if (step.text && step.text.hasOwnProperty(languageId)) {
					texts.push({
						id: languageId + '-' + taskData.taskId + '-' + step.id,
						text: step.text[languageId].replace(/<br \/>/g, '').replace(/&deg;/g, '')
					});
				}

				if (step.options && step.options.length > 0) {
					step.options.forEach((option) => {
						if (option.text && option.text.hasOwnProperty(languageId)) {
							texts.push({
								id: languageId + '-' + taskData.taskId + '-' + step.id + '-' + option.id,
								text: option.text[languageId].replace(/<br \/>/g, '').replace(/&deg;/g, '')
							});
						}
					});
				}
			});
		}
	}

	/* Get "spot errors" feedback texts */
	if (taskData.type === 'spot-errors') {
		if (taskData.errors && taskData.errors.length > 0) {
			taskData.errors.forEach((error) => {
				if (error && error.effects && error.effects.length > 0) {
					error.effects.forEach((effect, effectIndex) => {
						if (
							effect.type === 'feedback' && 
							effect.feedback &&
							effect.feedback.text &&
							effect.feedback.text.hasOwnProperty(languageId)
						) {
							texts.push({
								id: languageId + '-' + taskData.taskId + '-' 
									+ error.id + '-feedback-' + (effectIndex + 1),
								text: effect.feedback.text[languageId].replace(/<br \/>/g, '').replace(/&deg;/g, '')
							});	
						}
					});
				}
			});
		}
	}

	return texts;
};


/**
 * Get texts of module
 * @param {object} moduleData 
 * @param {string} languageId 
 * @returns 
 */
const getTextsFromModule = (moduleData, languageId) => {
	let texts = [];
	if (moduleData && moduleData.tasks && moduleData.tasks.length > 0) {
		moduleData.tasks.forEach((taskData) => {
			const taskTexts = getTextsFromTask(taskData, languageId);
			if (taskTexts.length > 0) {
				texts = texts.concat(taskTexts);
			}
		});
	}
	return texts;
};


/**
 * Get texts for tasks of selected modules
 * @param {array} selectedModuleIds 
 * @param {string} languageId 
 * @returns 
 */
const getTextsFromModules = (selectedModuleIds, languageId) => {
	let texts = [];
	scenariosData.forEach((scenarioData) => {
		if (scenarioData.modulesData && scenarioData.modulesData.length > 0) {
			scenarioData.modulesData.forEach((moduleData) => {
				if (selectedModuleIds.includes(moduleData.id)) {
					const moduleTexts = getTextsFromModule(moduleData, languageId);
					if (moduleTexts.length > 0) {
						texts = texts.concat(moduleTexts);
					}
				}
			});
		}
	});

	return texts;
};


export {
	getTextsFromModules
};