const moduleImages = [
	'modules/tasks/order/s2-m1-scratch-test/item-1.svg',
	'modules/tasks/order/s2-m1-scratch-test/item-2.svg',
	'modules/tasks/order/s2-m1-scratch-test/item-3.svg',
	'modules/tasks/order/s2-m1-scratch-test/item-4.svg',
	'modules/tasks/order/s2-m1-scratch-test/item-5.svg',

	'modules/tasks/spot-errors/s2-m1-truck/background.svg',
];

export {
	moduleImages
};