import React, {Component} from 'react';
import PropTypes from 'prop-types';
import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import {DndProvider} from 'react-dnd-multi-backend';
import {HTML5toTouch} from 'rdndmb-html5-to-touch';
import appConfig from 'config/app.config';
import {playerDataTemplate} from 'data/templates/player-data-template';
import {languagesData} from 'data/languages-data';
import Loading from 'components/loading/loading';
import PlayerLobby from 'components/player-lobby/player-lobby';
import ChooseLanguage from 'components/choose-language/choose-language';
import Game from 'components/game/game';

class PlayerController extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isLoading: true,
			isConnectedToGame: false,
			page: 'game',
		};
	}

	/**
	 * Component did mount
	 */
	componentDidMount = () => {
		if (!this.props.gameData) {
			this.setState({isLoading: false});

		} else if (!this.props.userData.gameId) {
			/* Player not connected to any game, connect to this game */
			this.updateUser({gameId: this.props.gameData.id}).then(() => {
				this.setState({isLoading: false, isConnectedToGame: true});	
			}).catch((error) => {
				console.error(error);
				// TODO: handle error updating player
				this.setState({isLoading: false});
			});
		} else {
			/* Player is connected to a game */
			if (this.props.userData.gameId === this.props.gameData.id) {
				/* Player is connected to this game */
				this.setState({isLoading: false, isConnectedToGame: true});	
			} else {
				/* Player is connected to a different game */
				this.setState({isLoading: false});
			}
		}
	};

	/**
	 * Update user (player) data
	 * @param {object} updates 
	 * @returns 
	 */
	updateUser = (updates) => {
		/* Nothing to update */
		if (Object.keys(updates).length === 0 && updates.constructor === Object) {
			return new Promise((resolve)=>{resolve();});
		}

		/* Get user id */
		const userId = this.props.userData.id;

		/* Update user data */
		const db = firebase.firestore();
		const userRef = db.collection(appConfig.usersDbName).doc(userId);
		return userRef.update(updates);
	};

	/**
	 * Add survey response to database
	 * @param {object} surveyResponse 
	 * @returns 
	 */
	saveSurveyResponse = (surveyResponse) => {
		const db = firebase.firestore();
		return db.collection(appConfig.surveysDbName).add(surveyResponse);
	};

	/**
	 * Switch game
	 * @param {string} gameId 
	 */
	switchPlayerGame = (gameId) => {
		const db = firebase.firestore();
		return db.collection(appConfig.usersDbName).doc(this.props.userData.id).update({
			gameId: gameId,
			modules: []
		});
	};

	/**
	 * Reset player game data for this game
	 */
	resetPlayerGameData = () => {
		/* Reset data in database */
		this.updateUser(
			{...Object.assign({}, 
				playerDataTemplate, 
				{gameId: this.props.userData.gameId}
			)}
		);
	};

	/**
	 * Choose language
	 * @param {string} languageId 
	 */
	handleChooseLanguage = (languageId) => {
		this.updateUser({languageId}).then(() => {
			if (this.state.page !== 'game') this.setState({page: 'game'});
		});
	};

	/**
	 * Go to page
	 * @param {string} page 
	 */
	handleGoToPage = (page) => {
		this.setState({page: page});
	};

	/**
	 * Render component
	 */
	render = () => {
		/* Loading */
		if (this.state.isLoading) {
			return (
				<Loading languageId={this.props.languageId} handleLogout={this.props.handleLogout} />
			);
		}

		/* Get language id (use browser language if user has not selected language) */
		const languageId = (
			this.props.userData && 
			this.props.userData.languageId && 
			languagesData.some((l) => {return l.id === this.props.userData.languageId;})
				? this.props.userData.languageId
				: this.props.languageId
		);

		/* Not connected to game */
		if (!this.state.isConnectedToGame) {
			return (
				<PlayerLobby 
					languageId={languageId}
					userData={this.props.userData}
					gameData={this.props.gameData}
					switchPlayerGame={this.switchPlayerGame}
					handleLogout={this.props.handleLogout}
				/>
			);
		}

		const scenarioId = (this.props.gameData.scenarioId ? this.props.gameData.scenarioId : 'scenario-1');

		/* Select language */
		if (!this.props.userData.languageId || this.state.page === 'choose-language') {
			return (
				<ChooseLanguage 
					languageId={languageId}
					role={'player'}
					scenarioId={scenarioId}
					handleChooseLanguage={this.handleChooseLanguage}
					handleLogout={this.props.handleLogout}
				/>
			);
		}

		return (
			<DndProvider options={HTML5toTouch}>
				<Game 
					languageId={languageId}
					backgroundStatus={this.props.backgroundStatus}
					userData={this.props.userData}
					gameData={this.props.gameData}
					deviceInfo={this.props.deviceInfo}
					scrollToTop={this.props.scrollToTop}
					handleGoToPage={this.handleGoToPage}
					updateUser={this.updateUser}
					resetPlayerGameData={this.resetPlayerGameData}
					saveSurveyResponse={this.saveSurveyResponse}
					handleLogout={this.props.handleLogout}
					setBackground={this.props.setBackground}
					handleShakeScreen={this.props.handleShakeScreen}
				/>
			</DndProvider>
		);
	};
};

PlayerController.propTypes = {
	languageId: PropTypes.string.isRequired,
	backgroundStatus: PropTypes.string.isRequired,
	deviceInfo: PropTypes.object.isRequired,
	userData: PropTypes.object.isRequired,
	gameData: PropTypes.object,
	scrollToTop: PropTypes.func.isRequired,
	handleLogout: PropTypes.func.isRequired,
	setBackground: PropTypes.func.isRequired,
	handleShakeScreen: PropTypes.func.isRequired

};

export default PlayerController;
