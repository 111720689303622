import React, {useState, useEffect, useRef} from 'react';
import PropTypes from 'prop-types';
import {shuffleArray} from 'helpers/array-helper';
import {getText} from 'helpers/language-helper';
import {matchPoints} from 'data/points-data';
import TaskIntro from '../task-intro/task-intro';
import Audio from 'components/ui/audio/audio';
import './match.scss';

const Match = (props) => {
	/* Props */
	const {
		languageId,
		playerTaskData, 
		taskData,
		moduleData,
		updateLoggedTime,
		handleInstantTaskEffects,
		handleCompleteTask
	} = props;

	/* Timeout */
	const timeout = useRef(null);

	/* Check if completed already */
	const isCompleted = (playerTaskData && playerTaskData.isCompleted === true ? true : false);

	/* Chceck if game is paused */
	const [isPaused, setIsPaused] = useState(false);

	/* Task id */
	const [taskId, setTaskId] = useState(null);

	/* Category ids, item ids, matched item ids, points, errors */
	const [categoryIds, setCategoryIds] = useState([]);
	const [itemIds, setItemIds] = useState([]);
	const [matchedItemIds, setMatchedItemIds] = useState([]);
	const [points, setPoints] = useState(0);
	const [errors, setErrors] = useState(0);

	/* Selected category id / item id */
	const [selectedCategoryId, setSelectedCategoryId] = useState(null);
	const [selectedItemId, setSelectedItemId] = useState(null);

	/* Animate selected options */
	const [animateSelectedMatch, setAnimateSelectedMatch] = useState(false); 

	/**
	 * Get category ids and shuffle them
	 */
	const getCategoryIds = () => {
		if (!taskData.categories) return [];
		return shuffleArray(taskData.categories.map((category) => {return category.id;}));
	};

	/**
	 * Get item ids and shuffle them
	 */
	const getItemIds = () => {
		if (!taskData.items) return [];
		return shuffleArray(taskData.items.map((item) => {return item.id;}));
	};

	/**
	 * Get item ids that have already been matched
	 * @returns {array} matchedItemIds
	 */
	const getMatchedItemIds = () => {
		let matchedItemIds = [];
		if (playerTaskData && playerTaskData.matchedItemIds) {
			matchedItemIds = playerTaskData.matchedItemIds;
		}
		return matchedItemIds;
	};


	/**
	 * Selected a category / item
	 * @param {string} type 
	 * @param {string} selectedId 
	 * @returns 
	 */
	const selectCategoryOrItem = (type, selectedId) => {
		/* Already completed */
		if (isCompleted === true) return;

		/* Already selected */
		if (type === 'category' && selectedCategoryId === selectedId) {
			setSelectedCategoryId(null);
			return;
		}
		if (type === 'item' && selectedItemId === selectedId) {
			setSelectedItemId(null);
			return;
		}

		/* Update logged time */
		updateLoggedTime();

		/* Select category/item */
		if (type === 'category') {
			setSelectedCategoryId(selectedId);
		} else if (type === 'item') {
			setSelectedItemId(selectedId);
		}
	};

	/**
	 * Check if all items in specific category has been matched
	 * @param {object} categoryData 
	 * @param {array} matchedItemIds 
	 * @returns 
	 */
	const checkIfCategoryIsComplete = (categoryData, matchedItemIds) => {
		let isComplete = true;
		if (categoryData && categoryData.itemIds) {
			categoryData.itemIds.forEach((itemId) => {
				if (!isComplete) return;
				if (!matchedItemIds.includes(itemId)) {
					isComplete = false;
				}
			});
		} 
		return isComplete;
	};

	/**
	 * Check if selected category / item matches
	 */
	const checkIfMatch = () => {
		/* Pause game */
		setIsPaused(true);

		/* Enable animation */
		setAnimateSelectedMatch(true);

		/* Get data for selected category and item */
		const categoryData = taskData.categories.find((c) => {return c.id === selectedCategoryId;});
		const itemData = taskData.items.find((i) => {return i.id === selectedItemId;});
		
		/* Check if match */
		const isMatch = (categoryData && categoryData.itemIds.includes(selectedItemId));

		/* Automatically de-select option and un-pause game after a delay */
		if (timeout.current) clearTimeout(timeout.current);
		timeout.current = setTimeout(() => {
			setAnimateSelectedMatch(false);
			setSelectedCategoryId(null);
			setSelectedItemId(null);
			setIsPaused(false);
		}, 500);

		if (isMatch) {
			/* Add item id to list of matched item ids */
			const newMatchedItemIds = JSON.parse(JSON.stringify(matchedItemIds));
			newMatchedItemIds.push(selectedItemId);
			setMatchedItemIds(newMatchedItemIds);

			/* Handle effects */
			let newPoints = points;
			let instantEffects = [];
			if (itemData && itemData.effects.length > 0) {
				itemData.effects.forEach((effect) => {
					if (effect.type === 'feedback') {
						/* Visual effect (e.g. feedback / popup) */
						let effectObj = JSON.parse(JSON.stringify(effect));
						instantEffects.push(effectObj);
					} else {
						console.error('unknown effect');
					}
				});
			}

			/* Update points */
			setPoints(newPoints);

			/* Check if task is completed */
			const taskIsCompleted = (newMatchedItemIds.length >= taskData.items.length);
			if (taskIsCompleted) {
				/* Complete task, instant effects -> normal effects */
				completeTask(newMatchedItemIds, newPoints, instantEffects);
			} else {
				/* Instant effects (shown while task is still not completed) */
				if (instantEffects.length > 0) handleInstantTaskEffects(instantEffects);
			}
		} else {
			/* No match - increase error count */
			const newErrors = errors + 1;
			setErrors(newErrors);
		}
	};

	/**
	 * Complete task
	 */
	const completeTask = (newMatchedItemIds, newPoints, effects) => {
		/* Prepare array of effects */
		let newEffects = (effects ? [...effects] : []);

		/* Calculate points */
		let points = matchPoints.minPoints;
		let pointIndex = matchPoints.pointLimits.findIndex((limit) => {return errors <= limit;});
		if (pointIndex >= 0) points = matchPoints.pointValues[pointIndex];
		points += newPoints;


		/* Save completed task */
		handleCompleteTask(
			'match',
			points,
			errors,
			newEffects,
			{matchedItemIds: newMatchedItemIds}
		);
	};

	/**
	 * If a category AND an item are selected, check if they match
	 */
	useEffect(() => {
		if (selectedCategoryId && selectedItemId) {
			checkIfMatch();
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selectedCategoryId, selectedItemId]);

	
	/**
	 * New task id
	 */
	useEffect(() => {
		setCategoryIds(getCategoryIds());
		setItemIds(getItemIds());
		setMatchedItemIds(getMatchedItemIds());
		setSelectedCategoryId(null);
		setSelectedItemId(null);
		setAnimateSelectedMatch(false);
		setErrors(0);
		setPoints(0);
		setTaskId(taskData.id);
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [taskData.id]);

	return (
		<div className={'Match'}>	
			{taskData.id === taskId && <div className='Match-content'>
				<div className="Match-intro">
					<TaskIntro 
						languageId={languageId}
						moduleId={moduleData.id}
						text={taskData.text ? getText(taskData.text, languageId) : null}
						fileName={languageId + '-' + taskData.taskId}
					/>
				</div>
			
				<div className="Match-categoriesAndItems">
					{/* Categories column */}
					<div className="Match-categoriesWrap">
						{/* Categories title */}
						{taskData.categoriesTitle && 
							<div className="Match-categoriesTitle">
								<span>{getText(taskData.categoriesTitle, languageId)}</span>
							</div>}
						{/* Categories */}
						<div className="Match-categories">
							{categoryIds.map((categoryId) => {
								const categoryData = taskData.categories.find((c) => {return c.id === categoryId;});
								if (!categoryData) return null;
								
								const isSelected = (selectedCategoryId === categoryId);
								const categoryIsCompleted = checkIfCategoryIsComplete(categoryData, matchedItemIds);
								const canSelect = !isCompleted && !isPaused && !categoryIsCompleted;
								
								let categoryClass = 'Match-category ' + 
									taskData.categoriesType + ' category-' + categoryId;
								if (isSelected) {
									categoryClass += ' selected';
									if (selectedItemId && animateSelectedMatch) {
										if (categoryData.itemIds.includes(selectedItemId)) {
											categoryClass += ' animateCorrect';
										} else {
											categoryClass += ' animateWrong';
										}
									}
								} else if (categoryIsCompleted) {
									categoryClass += ' completed';
								} 

								return (
									<div 
										key={categoryId}
										className={categoryClass}
										onClick={() => {if (canSelect) selectCategoryOrItem('category', categoryId);}}
									>
										{categoryData.text && 
											<span>{getText(categoryData.text, languageId)}</span>
										}
										<div className="Match-audio">
											<Audio 
												type='task-option'
												fileName={languageId + '-' + taskData.taskId + '-cat-' + categoryId}
											/>
										</div>
									</div>
								);
							})}
						</div>
					</div>

					{/* Items column */}
					<div className="Match-itemsWrap">
						{/* Items title */}
						{taskData.itemsTitle && 
							<div className="Match-itemsTitle">
								<span>{getText(taskData.itemsTitle, languageId)}</span>
							</div>}
						{/* Items */}
						<div className="Match-items">
							{itemIds.map((itemId) => {
								const itemData = taskData.items.find((i) => {return i.id === itemId;});
								if (!itemData) return null;
								
								const isSelected = (selectedItemId === itemData.id);
								const itemIsCompleted = matchedItemIds.includes(itemId);
								const canSelect = (!isCompleted && !itemIsCompleted && !isPaused);
							
								let itemClass = 'Match-item ' + (taskData.itemsType) + ' item-' + itemId;
								if (isSelected) {
									itemClass += ' selected';
									if (selectedCategoryId && animateSelectedMatch) {
										const categoryData = 
											taskData.categories.find((c) => {return c.id === selectedCategoryId;});
										if (categoryData && categoryData.itemIds.includes(selectedItemId)) {
											itemClass += ' animateCorrect';
										} else {
											itemClass += ' animateWrong';
										}
									}
								} else if (itemIsCompleted) {
									itemClass += ' completed';
								}
								return (
									<div 
										key={itemId}
										className={itemClass} 
										onClick={() => {if (canSelect) selectCategoryOrItem('item', itemId);}}
									>
										{itemData.text && 
											<span>{getText(itemData.text, languageId)}</span>
										}
										<div className="Match-audio">
											<Audio 
												type='task-option'
												fileName={languageId + '-' + taskData.taskId + '-' + itemId}
											/>
										</div>
									</div>
								);
							})}
						</div>
					</div>
				</div>
			</div>}
		</div>
	);
};

Match.propTypes = {
	languageId: PropTypes.string.isRequired,
	playerTaskData: PropTypes.object,
	moduleData: PropTypes.object.isRequired,
	taskData: PropTypes.object.isRequired,
	updateLoggedTime: PropTypes.func.isRequired,
	handleInstantTaskEffects: PropTypes.func.isRequired,
	handleCompleteTask: PropTypes.func.isRequired
};

export default Match;
