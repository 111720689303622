/* Stars */
const fakeLoginsData = [
	{
		id: 'facilitator-1', 
		text: 'Facilitator 1'
	},
	{
		id: 'facilitator-2',
		text: 'Facilitator 2'
	},
	{
		id: 'player-1',
		text: 'Diana'
	},
	{
		id: 'player-2',
		text: 'Itai'
	},
	{
		id: 'player-3',
		text: 'Mads'
	},
	{
		id: 'player-4',
		text: 'Player 4'
	},
	{
		id: 'player-5',
		text: 'Player 5'
	}
];
export {
	fakeLoginsData
};