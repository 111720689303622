// NOTE: remember to update the UI-texts file on the drive, if you update this file */
const loginUiTexts = {
	login: {
		da: 'Log ind',
		en: 'Log in',
	},
	logout: {
		da: 'Log ud',
		en: 'Log out',
	},
	player: {
		da: 'Spiller',
		en: 'Player',
	},
	facilitator: {
		da: 'Facilitator',
		en: 'Facilitator',
	},
	admin: {
		da: 'Administrator',
		en: 'Administrator',
	},
	email: {
		da: 'e-mail',
		en: 'e-mail',
	},
	password: {
		da: 'password',
		en: 'password',
	},
	passwordRepeat: {
		da: 'gentag password',
		en: 'repeat password',
	},
	name: {
		da: 'navn',
		en: 'name',
	},
	email: {
		da: 'e-mail',
		en: 'e-mail'
	},
	reset: {
		da: 'Nulstil',
		en: 'Reset',
	},
	showFakeLogin: {
		da: 'Vis pseudo-login',
		en: 'Show pseudo login'
	}
};




module.exports = {
	loginUiTexts
};