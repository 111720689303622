/* Always loaded */
const basicImagesData = [
	'backgrounds/outside-animation.svg',
	'backgrounds/outside.svg',
	'backgrounds/production.svg',
	'icons/icon-cookies.svg',
	'icons/icon-loading.svg',
	'icons/icon-loading-white.svg',
	'icons/icon-language.svg',
	'icons/icon-logout.svg',
	'icons/icon-logout-2.svg',
	'languages/da.svg',
	'languages/en.svg',
	'logo-cgl.svg',
	'logo-vja.svg',
];

/* Loaded if logged in as player */
const basicGameImagesData = [
	'characters/manager.svg',
	'icons/icon-audio-pause-blue.svg',
	'icons/icon-audio-pause.svg',
	'icons/icon-audio-play-blue.svg',
	'icons/icon-audio-play.svg',
	'icons/icon-checkmark.svg',
	'icons/icon-correct.svg',
	'icons/icon-drag.svg',
	'icons/icon-drag-dark.svg',
	'icons/icon-home.svg',
	'icons/icon-next.svg',
	'icons/icon-star-filled.svg',
	'icons/icon-star.svg',
	'icons/icon-wrong.svg',
];

/* Loaded if logged in as facilitator/admin */
const facilitatorImagesData = [
	'icons/icon-arrow-down.svg',
	'icons/icon-cross-white.svg',
	'icons/icon-sort-asc.svg',
	'icons/icon-sort-desc.svg',
];

const imagesData = {
	'basic': basicImagesData,
	'basic-game': basicGameImagesData,
	'facilitator': facilitatorImagesData,
	'module-s1-m1': require('data/images/s1-m1').moduleImages,
	'module-s2-m1': require('data/images/s2-m1').moduleImages,
	'module-s3-m1': require('data/images/s3-m1').moduleImages
};

export {
	imagesData
};
