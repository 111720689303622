import React, {Component} from 'react';
import PropTypes from 'prop-types';
import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import dayjs from 'dayjs';
import appConfig from 'config/app.config';
import {getText} from 'helpers/language-helper';
import {errorUiTexts} from 'data/ui-texts/error-ui-texts';
import {loginUiTexts} from 'data/ui-texts/login-ui-texts';
import Loading from 'components/loading/loading';
import ImageLoader from 'components/ui/image-loader/image-loader';
import PlayerController from './player-controller';
import FacilitatorController from './facilitator-controller';

class UserController extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isLoading: true,
			errMsg: null,
			authData: null,
			gamesData: [],
			userData: null,
			section: null
		};
		this.unsubscribeGamesData = null;
		this.unsubscribeUserData = null;
	}

	/**
	 * Component did mount
	 */
	componentDidMount = () => {
		/* Get auth data */
		let authData = {
			id: this.props.authData.id ? this.props.authData.id : null,
			name: this.props.authData.name ? this.props.authData.name : null,
			email: this.props.authData.email ? this.props.authData.email.toLowerCase() : null
		};

		/* Get games data */
		this.subscribeToGamesData().then(() => {
			/* Check if user exists */
			if (authData && authData.id && authData.email) {
				this.setState({authData}, () => {
					const db = firebase.firestore();
					db.collection(appConfig.usersDbName).doc(authData.id).get().then((doc) => {
						if (doc.exists) {
							/* User exists, subscribe to their data */
							this.subscribeToUserData().then((response) => {
								if (response.status === 'ok') {	
									/* Update last login and maybe role */
									const userUpdates = {lastLogin: dayjs(new Date()).format('YYYY-MM-DD')};
									const isFacilitator = this.state.gamesData.some((g) => {
										return g.facilitatorEmails && g.facilitatorEmails.includes(authData.email);
									});
									if (isFacilitator && this.state.userData.role === 'player') {
										userUpdates.role = 'facilitator';
									}
									this.updateUser(userUpdates).then(() => {
										this.setState({isLoading: false});	
									}).catch((error) => {
										this.setState({isLoading: false});	
										console.error(error);
									});
									
								} else {
									// TODO: probably connection error? show err msg?
									this.setState({isLoading: false});
								}
							});
						} else {
							/* User does not exist, create new user and subscribe to their data */
							this.createNewUser().then(() => {
								this.subscribeToUserData().then((response) => {
									if (response.status === 'ok') {
										this.setState({isLoading: false});	
									} else {
										// TODO: probably connection error? show err msg?
										this.setState({isLoading: false});
									}
								});
							}).catch((err) => {
								// TODO
								console.error(err);
							});
						}
					});
				});
			} else {
				console.error('no auth data');
			}
		});

		
	};

	/**
	 * Component will unmount
	 */
	componentWillUnmount = () => {
		/* Cancel subscribtions */
		if (this.unsubscribeGamesData !== null) this.unsubscribeGamesData();
		if (this.unsubscribeUserData !== null) this.unsubscribeUserData();
	};

	/**
	 * Create new user
	 * @returns 
	 */
	createNewUser = () => {
		const userObj = {
			email: this.state.authData.email,
			name: this.state.authData.name,
			role: 'player',
			lastLogin: dayjs(new Date()).format('YYYY-MM-DD'),
			created: dayjs(new Date()).format('YYYY-MM-DD'),
		};
		const db = firebase.firestore();
		return db.collection(appConfig.usersDbName).doc(this.state.authData.id).set(userObj);
	};

	/**
	 * Subscribe to all games
	 * @returns {Promise}
	 */
	subscribeToGamesData = () => {
		/* Cancel previous subscription */
		if (this.unsubscribeGamesData !== null) this.unsubscribeGamesData();
		
		/* Subscribe to games */
		const db = firebase.firestore();
		return new Promise((resolve) => {
			this.unsubscribeGamesData = db.collection(appConfig.gamesDbName).onSnapshot((querySnapshot) => {
				let gamesData = [];
				querySnapshot.forEach((doc) => {gamesData.push({id: doc.id, ...doc.data()});});
				this.setState({gamesData: gamesData}, () => {resolve({status: 'success'});});
			},
			(error) => {
				/* Error: Could not get games data */
				console.error('Could not get games data: ', error);
				resolve({status: 'error', error: error});
			});
		});
	};

	/**
	 * Subscribe to user data
	 * @returns {Promise}
	 */
	subscribeToUserData = () => {
		/* Cancel previous subscribtion */
		if (this.unsubscribeUserData !== null) this.unsubscribeUserData();

		/* Subscribe to user data */
		const db = firebase.firestore();
		return new Promise((resolve) => {
			this.unsubscribeUserData = db.collection(appConfig.usersDbName).doc(this.state.authData.id)
				.onSnapshot((doc) => {
					if (doc.exists) {
						/* Save user data to state */
						const userData = {id: doc.id, ...doc.data()};
						this.setState({userData}, () => {resolve({status: 'ok'});});
						if (userData.role === 'facilitator' || userData.role === 'admin') {
							this.props.setBackground(this.props.languageId, 'facilitator');
						}
					} else {
						/* User does not exist in database */
						resolve({status: 'ok'});
					}
				},
				(error) => {
					/* Error: Could not get user data */
					console.error('Could not get user data: ', error);
					resolve({status: 'error', error: error});
				});
		});
	};


	/**
	 * Update user data
	 * @param {object} updates
	 * @returns {promise}
	 */
	updateUser = (updates) => {
		/* Nothing to update */
		if (Object.keys(updates).length === 0 && updates.constructor === Object) {
			return new Promise((resolve)=>{resolve();});
		}

		/* Update user data */
		const userId = this.state.userData.id;
		const db = firebase.firestore();
		return db.collection(appConfig.usersDbName).doc(userId).update(updates);
	};


	/**
	 * Render component
	 */
	render = () => {
		/* Loading */
		if (this.state.isLoading) {
			return (
				<Loading languageId={this.props.languageId} deviceInfo={this.props.deviceInfo} />
			);
		}

		/* Auth error */
		if (!this.state.userData) {
			return (
				<div>
					<p>{getText(errorUiTexts.unknownUserOrGame, this.props.languageId)}</p>
					<button onClick={() => {this.props.handleLogout();}}>
						{getText(loginUiTexts.logout, this.props.languageId)}
					</button>
				</div>
			);
		}

		/* Component depends on user role */
		let Component = PlayerController;
		if (this.state.userData.role === 'facilitator' || this.state.userData.role === 'admin') {
			Component = FacilitatorController;
		}
		
		return (
			<>
				<ImageLoader type={this.state.userData.role === 'player' ? 'basic-game' : 'facilitator'} />
				<Component 
					languageId={this.props.languageId}
					backgroundStatus={this.props.backgroundStatus}
					deviceInfo={this.props.deviceInfo}	
					userData={this.state.userData}
					gameData={this.props.gameData}
					updateUser={this.updateUser}
					setBackground={this.props.setBackground}
					handleShakeScreen={this.props.handleShakeScreen}
					scrollToTop={this.props.scrollToTop}
					handleLogout={this.props.handleLogout} 
				/>
			</>
			
		);
	};
}

UserController.propTypes = {
	languageId: PropTypes.string.isRequired,
	backgroundStatus: PropTypes.string.isRequired,
	deviceInfo: PropTypes.object.isRequired,
	authData: PropTypes.object.isRequired,
	gameData: PropTypes.object,
	setBackground: PropTypes.func.isRequired,
	handleShakeScreen: PropTypes.func.isRequired,
	handleLogout: PropTypes.func.isRequired,
	scrollToTop: PropTypes.func.isRequired
};

export default UserController;
