import React, {Component} from 'react';
import PropTypes from 'prop-types';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import appConfig from 'config/app.config';
import apiHelper from 'helpers/api-helper';
import {getCookie} from 'helpers/cookie-helper';
import {getText} from 'helpers/language-helper';
import {getGameUrl} from 'helpers/game-helper';
import {errorUiTexts} from 'data/ui-texts/error-ui-texts';
import {scenariosData} from 'data/scenarios-data';
import {backgroundsData} from 'data/backgrounds-data';
import {fakeLoginsData} from 'data/fake-logins-data';
import Login from './login';
import UserController from 'components/users/user-controller';
import Background from 'components/ui/background/background';
import './login.scss';

class LoginController extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isLoggedIn: false,
			userId: null,
			email: null,
			gameData: null,
			backgroundId: 'outside',
			backgroundModifier: 'top',
			backgroundStatus: 'show',
			shakeScreen: false,
			bgLanguageId: this.props.languageId
		};
		this.timeout = null;
		this.unsubscribeOnAuthStateChanged = null;
	}

	/**
	 * Component did mount
	 */
	componentDidMount = () => {
		/* Get game data (if valid url) */
		this.getGameData().then(() => {
			if (this.state.gameData || getGameUrl(window.location.pathname) === 'facilitator') {
				/* Check if logged in */
				this.checkIfLoggedIn();
			}
		});
	};

	/**
	 * Get game data
	 * @returns {Promise}
	 */
	getGameData = () => {
		return new Promise((resolve)=>{
			/* Check if valid game url */
			const gameUrl = getGameUrl(window.location.pathname);
		
			if (!gameUrl || gameUrl.length === 0 || gameUrl === '/') {
				/* Site root */
				this.setState({gameData: null}, () => {
					resolve({status: 'success'});
				});
			} else {
				/* Get game data from url */
				const db = firebase.firestore();
				db.collection(appConfig.gamesDbName).get().then((querySnapshot) => {
					let gameData = null;
					querySnapshot.forEach((doc) => {
						if (gameData) return;
						if (doc.data() && doc.data().url === gameUrl.toLowerCase()) {
							gameData = {id: doc.id, ...doc.data()};
						}
					});
					if (gameData && gameData.scenarioId) {
						/* Check if login is required */
						const scenarioData = scenariosData.find((s) => {return s.id === gameData.scenarioId;});
						if (scenarioData) {
							this.setState({gameData}, () => {
								resolve({status: 'success'});
							});
						} else {
							/* No scenario data */
							this.setState({gameData: null}, () => {
								resolve({status: 'success'});
							});
						}
					} else {
						/* No game at this url */
						this.setState({gameData: null}, () => {
							resolve({status: 'success'});
						});
					}
				}).catch(() => {
					/* Error getting game data */
					this.setState({gameData: null}, () => {
						resolve({status: 'success'});
					});
				});
			}
		});
	};

	/**
	 * Check if user is logged in
	 */
	checkIfLoggedIn = () => {
		// Unsubscribe previous onAuthStateChanged
		if (this.unsubscribeOnAuthStateChanged !== null) {
			this.unsubscribeOnAuthStateChanged();
		}

		// Subscribe to onAuthStateChanged
		this.unsubscribeOnAuthStateChanged = firebase.auth().onAuthStateChanged((user)=>{
			if (user) {
				const isFakeLogin = (
					appConfig.env !== 'production' && 
					!user.email && 
					fakeLoginsData.some((fakeLogin) => {return fakeLogin.id === user.uid;})
				);
				if (isFakeLogin) {
					this.setState({
						isLoggedIn: true,
						userId: user.uid,
						email: user.uid + '@cphgamelab.dk',
					});
				} else {
					/* Logged in as facilitator */
					this.setState({
						isLoggedIn: true,
						userId: user.uid,
						email: user.email,
						backgroundModifier: 'bottom',
						backgroundStatus: 'pan'
					});
				
				}
			} else {
				/* Not logged in */
				this.setState({
					isLoggedIn: false,
					userId: null,
					email: null,
					backgroundModifier: 'top'
				});
			}
		});	
	};

	/**
	 * Handle login
	 * @returns 
	 */
	handleLogin = () => {
		/* Check cookie consent */
		const cookieConsent = getCookie(appConfig.cookiesAcceptedCookieName);
		if (!cookieConsent || cookieConsent.length === 0) {
			this.setState({feedback: getText(errorUiTexts.cookiesNotAccepted, this.props.languageId)});
			return;
		}

		/* SSO login */
		const auth = firebase.auth();
		const provider = new firebase.auth.OAuthProvider('microsoft.com');
		provider.setCustomParameters({tenant: appConfig.tenant});
		auth.signInWithPopup(provider).then((data) => {
			this.setState({
				isLoggedIn: true, userId: data.user.uid, email: data.user.email, name: data.user.displayName
			});
		});
	};

	/**
	 * "Fake" login (not using SSO)
	 * @param {string} userId 
	 */
	handleFakeLogin = (userId) => {
		/* Check cookie consent */
		const cookieConsent = getCookie(appConfig.cookiesAcceptedCookieName);
		if (!cookieConsent || cookieConsent.length === 0) {
			this.setState({feedback: getText(errorUiTexts.cookiesNotAccepted, this.props.languageId)});
			return;
		}

		this.handleGetLoginToken(userId,);
	};


	/**
	 * Get a login token for player
	 * Call firebase auth to sign in with that token.
	 * @param {number} userId 
	 */
	handleGetLoginToken = (userId) => {
		apiHelper('player/join-game', {userId: userId}).then(
			(response)=>{
				if (response.status === 'success' && response.token) {
					this.loginWithToken(userId, response.token);
				} else {
					console.error(response);
					this.setState({
						isLoading: false,
						feedback: getText(errorUiTexts.unknownError, this.props.languageId)
					});
				}
			},
			(rejection) => {
				console.error('rejection: ', rejection);
				this.setState({
					isLoading: false,
					feedback: getText(errorUiTexts.unknownError, this.props.languageId)
				});
			}
		);
	};

	/**
	 * Login with token
	 * @param {string} token 
	 */
	loginWithToken = (userId, token) => {
		firebase.auth().signInWithCustomToken(token)
			.then(() => {
				this.setState({
					isLoggedIn: true,
					userId: userId,
					email: userId + '@cphgamelab.dk',
					name: userId
				});
			})
			.catch((error) => {
				console.error('sign in error', error);
				this.setState({
					feedback: getText(errorUiTexts.unknownError, this.props.languageId),
					isLoading: false,
					userId: null
				});
			});
	};

	/**
	 * Logout
	 */
	handleLogout = () => {		
		firebase.auth().signOut();
		this.setState({
			isLoggedIn: false,
			userId: null,
			email: null,
			name: null,
			backgroundId: 'outside',
			backgroundModifier: 'top',
			backgroundStatus: 'show',
		});
	};

	/**
	 * Switch between facilitator / player login box
	 * @param {string} activeLoginBox 
	 */
	setActiveLoginBox = (activeLoginBox) => {
		this.setState({activeLoginBox});
	};

	/**
	 * Switch background
	 * @param {string} languageId 
	 * @param {string} backgroundId 
	 * @param {string} backgroundModifier 
	 */
	setBackground = (languageId, backgroundId, backgroundModifier = null) => {
		if (
			backgroundId !== this.state.backgroundId || 
			backgroundModifier !== this.state.backgroundModifier
		) {
			/* Get background data */
			const backgroundData = backgroundsData.find((b) => {return b.id === backgroundId;});
			if (backgroundData) {
				/* Check position id */
				let newBackgroundModifier = backgroundModifier;
				if (
					backgroundData.modifiers.length > 0 &&
					(
						!backgroundModifier || 
						!backgroundData.modifiers.some((p) => {return p.id === newBackgroundModifier;})
					)
				) {
					/* Position required, but no position id or invalid position id provided */
					newBackgroundModifier = backgroundData.modifiers[0].id;
				}

				if (
					backgroundId !== this.state.backgroundId ||
					newBackgroundModifier !== this.state.backgroundModifier
				) {
					let backgroundStatus = (backgroundId === this.state.backgroundId ? 'pan' : 'fade');
					if (
						(this.state.backgroundModifier && this.state.backgroundModifier.includes('zoom')) || 
						(newBackgroundModifier && newBackgroundModifier.includes('zoom'))
					) {
						backgroundStatus = 'zoom';
					}
					this.setState({
						bgLanguageId: languageId,
						backgroundId: backgroundId,
						backgroundModifier: newBackgroundModifier,
						backgroundStatus: backgroundStatus
					});
				}
			}
		}
	};

	/**
	 * Update background status (show, pan, fade, zoom)
	 * @param {string} status 
	 */
	updateBackgroundStatus = (status) => {
		this.setState({backgroundStatus: status});
	};

	/**
	 * Shake background
	 */
	handleShakeScreen = () => {
		this.setState({shakeScreen:true}, () => {
			if (this.timeout) clearTimeout(this.timeout);
			this.timeout = setTimeout(() => {this.setState({shakeScreen: false});}, 500);
		});
	};

	/**
 	* Render component
 	*/
	render() {
		/* Get scenario id */
		const gameData = this.state.gameData;
		const scenarioId = (gameData && gameData.scenarioId ? gameData.scenarioId : 'scenario-1');

		return (
			<div className={'LoginController' + (this.state.shakeScreen ? ' shake' : '')}>
				<Background
					scenarioId={scenarioId} 
					bgLanguageId={this.state.bgLanguageId}
					backgroundId={this.state.backgroundId}
					backgroundModifier={this.state.backgroundModifier}
					backgroundStatus={this.state.backgroundStatus}
					updateBackgroundStatus={this.updateBackgroundStatus}
					deviceInfo={this.props.deviceInfo}
				/>
				{!this.state.isLoggedIn ?
					<Login 
						languageId={this.props.languageId}
						deviceInfo={this.props.deviceInfo}
						gameData={this.state.gameData}
						handleLogin={this.handleLogin}
						handleFakeLogin={this.handleFakeLogin}
					/>
					:
					<>
						<UserController 
							languageId={this.props.languageId}
							backgroundStatus={this.state.backgroundStatus}
							gameData={this.state.gameData}
							deviceInfo={this.props.deviceInfo}
							authData={{
								id: this.state.userId,
								name: this.state.name,
								email: this.state.email
							}}
							scrollToTop={this.props.scrollToTop}
							handleLogout={this.handleLogout}
							setBackground={this.setBackground}
							handleShakeScreen={this.handleShakeScreen}
						/>
					</>
				}
			</div>
		);
	}
}

LoginController.propTypes = {
	deviceInfo: PropTypes.object.isRequired,
	languageId: PropTypes.string.isRequired,
	scrollToTop: PropTypes.func.isRequired,
};

export default LoginController;