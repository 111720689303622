const moduleImages = [
	'modules/tasks/multiple-choice/s1-m1-mould/option-1.svg',
	'modules/tasks/multiple-choice/s1-m1-mould/option-2.svg',
	'modules/tasks/multiple-choice/s1-m1-mould/option-3.svg',
	'modules/tasks/multiple-choice/s1-m1-mould/option-4.svg',

	'modules/tasks/spot-errors/s1-m1-truck/background.svg',
	'modules/tasks/spot-errors/s1-m1-storage-2/background.svg',
];

export {
	moduleImages
};