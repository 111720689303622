import React, {useState} from 'react';
import PropTypes from 'prop-types';
import appConfig from 'config/app.config';
import {loginUiTexts} from 'data/ui-texts/login-ui-texts';
import {generalUiTexts} from 'data/ui-texts/general-ui-texts';
import {getText} from 'helpers/language-helper';
import {getGameUrl} from 'helpers/game-helper';
import {fakeLoginsData} from 'data/fake-logins-data';
import Logo from 'components/ui/logo/logo';
import Button from 'components/ui/button/button';
import CookieConsent from 'components/ui/cookie-consent/cookie-consent';
import './login.scss';

const Login = ({languageId, deviceInfo, gameData, handleLogin, handleFakeLogin}) => {
	/* Show fake logins */
	const [showFakeLogins, setShowFakeLogins] = useState(false);

	/* Game url */
	const gameUrl = getGameUrl(window.location.pathname);

	/* Show login if facilitator or game */
	const showLogin = gameData || (gameUrl === 'facilitator');

	return (
		<div className={'Login ' + deviceInfo.orientation}>
			{/* Logo */}
			<div className={'Login-logo ' + deviceInfo.orientation}><Logo /></div>
			<div className={'Login-title ' + deviceInfo.orientation}>
				<span>{getText(generalUiTexts.hygieneTraining, languageId)}</span>
			</div>
			
			{/* Login */}
			{showLogin && <div className="Login-loginBtn">
				<Button 
					classes={['login']}
					text={getText(loginUiTexts.login, languageId)}
					onClick={() => {handleLogin();}}
				/>
			</div>}

			{/* Fake login */}
			{(showLogin && showFakeLogins) && <div className="Login-fakeLogins">
				{fakeLoginsData.map((fakeLogin) => {
					return (
						<Button
							key={fakeLogin.id} 
							classes={['login']} 
							text={fakeLogin.text} 
							onClick={() => {handleFakeLogin(fakeLogin.id);}}
						/>
					);
				})}
			</div>}

			{(showLogin && appConfig.env !== 'production') && 
				<div 
					className={'Login-fakeLoginToggle' + (showFakeLogins ? ' checked' : '')}
					onClick={() => {setShowFakeLogins(!showFakeLogins);}}
				>
					<span></span>
					<span>{getText(loginUiTexts.showFakeLogin, languageId)}</span>
				</div>}

		
			{/* Cookies */}
			<CookieConsent languageId={languageId} page="login" />

			{/* CGL logo */}
			<div className="Login-logoCGL"><Logo type="cgl"/></div>			
		</div>


	);
};

Login.propTypes = {
	languageId: PropTypes.string.isRequired,
	deviceInfo: PropTypes.object.isRequired,
	gameData: PropTypes.object,
	handleLogin: PropTypes.func.isRequired,
	handleFakeLogin: PropTypes.func.isRequired,
};

export default Login;
