import React, {useState} from 'react';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import {sortArrayByProperty} from 'helpers/array-helper';
import {getText} from 'helpers/language-helper';
import {
	getLastCompletedModuleId, 
	checkIfModuleInModuleGroupIsDisabled,
	checkIfModuleGroupIsCompleted, 
	getModuleGroupCompletedDate
} from 'helpers/module-helper';
import {scenariosData} from 'data/scenarios-data';
import {loginUiTexts} from 'data/ui-texts/login-ui-texts';
import {facilitatorUiTexts} from 'data/ui-texts/facilitator-ui-texts';
import Button from 'components/ui/button/button';
import FacilitatorSurveysPopup from './facilitator-surveys-popup/facilitator-surveys-popup';
import FacilitatorDownloadStats from './facilitator-download-stats/facilitator-download-stats';
import './facilitator-players.scss';

const FacilitatorPlayers = ({languageId, gameData, players, gameSurveysResponses, setSelectedPlayerId}) => {
	/* Get scenario data */
	const scenarioId = (gameData.scenarioId ? gameData.scenarioId : 'scenario-1');
	const scenarioData = scenariosData.find((sc) => {return sc.id === scenarioId;});

	/* Sort players config */
	const [sortPlayersBy, setSortPlayersBy] = useState('id');
	const [sortPlayersDirection, setSortPlayersDirection] = useState('ASC');

	/* Show inactive players */
	const [showInactivePlayers, setShowInactivePlayers] = useState(true);

	/* Show surveys popup */
	const [showSurveysPopup, setShowSurveysPopup] = useState(false);

	/**
	 * Sort players by property
	 * @param {string} property 
	 */
	const handleSortByProperty = (property) => {
		if (property === sortPlayersBy) {
			sortPlayersDirection === 'ASC' ? setSortPlayersDirection('DESC') : setSortPlayersDirection('ASC');
		} else {
			setSortPlayersBy(property);
			setSortPlayersDirection('ASC');
		}
	};

	/* Filter and sort players */
	const gamePlayers = players.filter((player) => {return player.gameId === gameData.id;});
	const sortedPlayers = sortArrayByProperty(gamePlayers, sortPlayersBy, sortPlayersDirection);

	return (
		<div className={'FacilitatorPlayers'}>
			{/* Toggle "show inactive players" btn */}
			<div 
				className={'FacilitatorPlayers-toggleInactivePlayers' + (showInactivePlayers ? ' show' : '')} 
				onClick={() => {setShowInactivePlayers(!showInactivePlayers);}}
			>
				<span>{getText(facilitatorUiTexts.showInactivePlayers, languageId)}</span>
			</div>

			{/* Show survey popup btn */}
			<div className="FacilitatorPlayers-surveyPopupBtn">
				<Button 
					classes={['facilitator']} 
					text={getText(facilitatorUiTexts.viewSurveys, languageId)} 
					onClick={() => {setShowSurveysPopup(true);}} 
				/>
			</div>

			{/* Download stats btn */}
			<div className="FacilitatorPlayers-statsBtn">
				<FacilitatorDownloadStats languageId={languageId} game={gameData} players={sortedPlayers} />
			</div>


			{/* List of players */}
			<div className="FacilitatorPlayers-header">
				{/* Name */ }
				<div 
					className={'FacilitatorPlayers-cell id' 
								+ (sortPlayersBy === 'name' ? ' sortedBy ' + sortPlayersDirection : '')} 
					onClick={() => {handleSortByProperty('name');}}
				>
					<span>{getText(loginUiTexts.name, languageId)}</span>
				</div>
				{/* Email */}
				<div 
					className={'FacilitatorPlayers-cell email' 
						+ (sortPlayersBy === 'email' ? ' sortedBy ' + sortPlayersDirection : '')} 
					onClick={() => {handleSortByProperty('email');}}
				>
					<span>{getText(loginUiTexts.email, languageId)}</span>
				</div>
				
				<div 
					className={'FacilitatorPlayers-cell created' 
						+ (sortPlayersBy === 'created' ? ' sortedBy ' + sortPlayersDirection : '')} 
					onClick={() => {handleSortByProperty('created');}}
				>
					<span>{getText(facilitatorUiTexts.created, languageId)}</span>
				</div>
				<div 
					className={'FacilitatorPlayers-cell lastLogin' 
						+ (sortPlayersBy === 'lastLogin' ? ' sortedBy ' + sortPlayersDirection : '')} 
					onClick={() => {handleSortByProperty('lastLogin');}}
				>
					<span>{getText(facilitatorUiTexts.lastLogin, languageId)}</span>
				</div>
				{scenarioData.moduleGroups.map((moduleGroup) => {
					return (
						<div key={moduleGroup.id} className="FacilitatorPlayers-cell moduleGroup">
							<span>{getText(moduleGroup.title, languageId)}</span>
						</div>
					);
				})}
				<div className={'FacilitatorPlayers-cell lastModule'}>
					<span>{getText(facilitatorUiTexts.lastCompletedModule, languageId)}</span>
				</div>
			</div>
			<div className="FacilitatorPlayers-body">
				{sortedPlayers.map((player) => {
					const isInactive = (player.isInactive ? true : false);
					if (isInactive && !showInactivePlayers) return null;
					const created = (player.created ? dayjs(player.created).format('DD/MM-YY') : '-');
					const lastLogin = (player.lastLogin ? dayjs(player.lastLogin).format('DD/MM-YY') : '-');
					const lastCompletedModuleId = getLastCompletedModuleId(player, scenarioId);
					let lastCompletedModule = '-';
					if (lastCompletedModuleId) {
						lastCompletedModule = getText(scenarioData.modulesData.find((m) => {
							return m.id === lastCompletedModuleId;
						}).title, languageId);
					}
					return (
						<div 
							key={player.id} 
							className={'FacilitatorPlayers-row' + (isInactive ? ' inactive' : '')}
							onClick={() => {setSelectedPlayerId(player.id);}}
						>
							<div className="FacilitatorPlayers-cell id">
								<span>{player.name}</span>
								{player.isInactive && <span>{getText(facilitatorUiTexts.inactive, languageId)}</span>}
							</div>
							<div className="FacilitatorPlayers-cell email">{player.email}</div>
							<div className="FacilitatorPlayers-cell created"><span>{created}</span></div>
							<div className="FacilitatorPlayers-cell lastLogin"><span>{lastLogin}</span></div>
							{scenarioData.moduleGroups.map((moduleGroup) => {
								const isDisabled = checkIfModuleInModuleGroupIsDisabled(moduleGroup.moduleIds, player);
								const isCompleted = (isDisabled
									? false
									: checkIfModuleGroupIsCompleted(moduleGroup.moduleIds, player)
								);
								const completedDate = (isCompleted
									? getModuleGroupCompletedDate(moduleGroup.moduleIds, player)
									: null
								);
								return (
									<div 
										key={moduleGroup.id} 
										className={'FacilitatorPlayers-cell moduleGroup' 
											+ (isDisabled ? ' disabled' : '')
											+ (isCompleted ? ' completed' : '')
											+ (completedDate ? ' withDate' : '')}
									>
										{completedDate && <span>{dayjs(completedDate).format('DD/MM-YY')}</span>}
									</div>
								);
							})}
							<div className="FacilitatorPlayers-cell lastModule"><span>{lastCompletedModule}</span></div>
						</div>
					);
				})}
			</div>

			{showSurveysPopup && <FacilitatorSurveysPopup 
				languageId={languageId}
				gameData={gameData}
				gameSurveysResponses={gameSurveysResponses}
				togglePopup={setShowSurveysPopup}
			/>}
		</div>
	);
};

FacilitatorPlayers.propTypes = {
	languageId: PropTypes.string.isRequired,
	gameData: PropTypes.object.isRequired,
	players: PropTypes.array.isRequired,
	gameSurveysResponses: PropTypes.array.isRequired,
	setSelectedPlayerId: PropTypes.func.isRequired,
};

export default FacilitatorPlayers;
