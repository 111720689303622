const taskTemplates = require('data/templates/task-templates');
const {effectTemplate} = require('data/templates/effect-template');

let moduleTasks = [
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		taskId: 's1-m1-instructions-welcome',
		showProgressBar: false,
		background: 'outside',
		backgroundModifier: 'bottom',
		text: {
			da: `Velkommen til hygiejnetræningen for lager. Jeg er din teamleder og hjælper dig gennem spillet.`,
			en: ``,
		}
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		taskId: 's1-m1-instructions-points',
		showProgressBar: false,
		background: 'outside',
		backgroundModifier: 'bottom',
		text: {
			da: `I spillet får du point for at løse opgaver. Jo færre fejl du laver, jo flere point får du.`,
			en: ``
		}
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		taskId: 's1-m1-instructions-stars',
		background: 'outside',
		backgroundModifier: 'bottom',
		characters: ['manager pose-2 coat-2'],
		text: {
			da: `Når du får point, kan du også få stjerner. I toppen af skærmen kan du se, hvor mange stjerner og point du har.`,
			en: ``
		}
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		taskId: 's1-m1-instructions-welcome-three-stars',
		background: 'outside',
		backgroundModifier: 'bottom',
		text: {
			da: `I løbet af spillet følger vi varernes flow på lageret. Så vi starter med modtagelse af korn.`,
			en: ``
		}
	}),
	Object.assign({}, taskTemplates.orderTemplate.mainTemplate, {
		taskId: 's1-m1-order-receiving-goods',
		background: 'outside',
		backgroundModifier: 'bottom',
		text: {
			da: `Når du modtager, hvad skal du så gøre? Sæt nedenstående handlinger i den korrekte rækkefølge. Tryk OK, når du er klar.`,
			en: ``,
		},
		items: [
			Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
				id: 1,
				text: {
					da: `Se logbog fra chauffør`,
					en: ``
				},
				correctOrderNumbers: [1]
			}),
			Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
				id: 2,
				text: {
					da: `Se efter om afgrøder er beskadiget`,
					en: ``,
				},
				correctOrderNumbers: [2]
			}),
			Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
				id: 3,
				text: {
					da: `Lav kontrolvejning`,
					en: ``,
				},
				correctOrderNumbers: [3]
			}),
			Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
				id: 4,
				text: {
					da: `Skriv pakhus/silonummer på afgrødeseddel`,
					en: ``,
				},
				correctOrderNumbers: [4]
			}),
		]
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		taskId: 's1-m1-instructions-batch-number',
		background: 'outside',
		backgroundModifier: 'bottom',
		text: {
			da: `Når du modtager varer, så bliver der automatisk dannet et batchnummer i systemet. Det kan være relevant, hvis der for eksempel kommer kontrol.`,
			en: ``
		}
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		taskId: 's1-m1-instructions-receiving-actions',
		characters: ['manager pose-2 coat-2'],
		background: 'outside',
		backgroundModifier: 'bottom',
		text: {
			da: `Lad os kigge nærmere på et par af handlingerne, når du modtager.`,
			en: ``
		}
	}),
	Object.assign({}, taskTemplates.spotErrorsTemplate.mainTemplate, {
		taskId: 's1-m1-spot-errors-truck',
		layout: 's1-m1-truck',
		background: 'outside',
		backgroundModifier: 'bottom',
		text: {
			da: `Prøv at se, om du kan spotte de 3 problemer på det her billede. Tryk OK, når du har dem, eller hvis du ikke kan finde dem alle.`,
			en: ``,
		},
		errors: [
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {id: 'hole'}),
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {id: 'mould'}),
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {id: 'dirt'}),
		]
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 's1-m1-mc-mould',
		layout: 's1-m1-mould',
		subtype: 'images',
		background: 'outside',
		backgroundModifier: 'bottom',
		text: {
			da: `Hvis der er synlig mug eller klumper, så skal du afvise partiet. Men hvad kan også hjælpe dig til at spotte mug og svampepartikler?`,
			en: ``,
		},
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1, // Nose
				isCorrect: true,
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							text: {
								da: `Nemlig. Mug og skimmel har en kraftig og distinkt lugt, som du nemt kan genkende. Så brug næsen når du modtager varer.`,
								en: ``,
							}
						})
					})
				]
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2, // Magnifying glass
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3, // Microscope
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4, // Ear
			}),
		]
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		taskId: 's1-m1-instructions-logbook',
		background: 'outside',
		backgroundModifier: 'bottom',
		text: {
			da: `Ved at kigge i chaufførens logbog, kan du se, om de er problemer med varerne. Det handler især om, hvad der har været i vognen tidligere, og hvordan der er gjort rent.`,
			en: ``
		}
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 's1-m1-mc-last-three-loads',
		background: 'outside',
		backgroundModifier: 'bottom',
		text: {
			da: `Hvorfor er det vigtigt at vide, hvad vognen har kørt med de sidste tre læs?`,
			en: ``,
		},
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1,
				text: {
					da: `Der må ikke være overslæb fra tidligere læs`,
					en: ``
				},
				isCorrect: true
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2,
				text: {
					da: `Fordi der kan være allergener i kornet`,
					en: ``
				},
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3,
				text: {
					da: `Sådan er reglen bare`,
					en: ``
				},
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4,
				text: {
					da: `Fordi det kan have betydning for kontrolvejningen`,
					en: ``
				}
			})
		]
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		taskId: 's1-m1-instructions-previous-goods',
		background: 'outside',
		backgroundModifier: 'bottom',
		text: {
			da: `Alt efter hvad chaufføren har kørt med tidligere, kan der være forskellige krav til rengøring.`,
			en: ``
		}
	}),
	Object.assign({}, taskTemplates.matchTemplate.mainTemplate, {
		taskId: 's1-m1-match-abcd',
		background: 'outside',
		backgroundModifier: 'bottom',
		text: {
			da: `Hvad er ABCD-rengøring? Match rengøring og bogstav.`,
			en: ``,
		},
		categories: [
			Object.assign({}, taskTemplates.matchTemplate.categoryTemplate, {id: 1, text: {da: 'A', en: 'A'}, itemIds: [1]}),
			Object.assign({}, taskTemplates.matchTemplate.categoryTemplate, {id: 2, text: {da: 'B', en: 'B'}, itemIds: [2]}),
			Object.assign({}, taskTemplates.matchTemplate.categoryTemplate, {id: 3, text: {da: 'C', en: 'C'}, itemIds: [3]}),
			Object.assign({}, taskTemplates.matchTemplate.categoryTemplate, {id: 4, text: {da: 'D', en: 'D'}, itemIds: [4]}),
		],
		items: [
			Object.assign({}, taskTemplates.matchTemplate.itemTemplate, {
				id: 1, 
				text: {
					da: 'Fejning/afblæsning',
					en: ''
				}
			}),
			Object.assign({}, taskTemplates.matchTemplate.itemTemplate, {
				id: 2, 
				text: {
					da: 'Vask med vand',
					en: ''
				}
			}),
			Object.assign({}, taskTemplates.matchTemplate.itemTemplate, {
				id: 3, 
				text: {
					da: 'Vask med sæbe og vand',
					en: ''
				}
			}),
			Object.assign({}, taskTemplates.matchTemplate.itemTemplate, {
				id: 4, 
				text: {
					da: 'Vask med sæbe, vand og desinfektion',
					en: ''
				}
			}),
		]
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 's1-m1-mc-grain-flower-soil',
		background: 'outside',
		backgroundModifier: 'bottom',
		text: {
			da: `En chauffør kommer med et læs korn. Seneste kørsel har været med blomstermuld, og der er lavet A-rengøring - altså fejning. Skal du afvise bilen?`,
			en: ``,
		},
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1,
				text: {
					da: `Ja, der skulle have været B-rengøring`,
					en: ``
				},
				isCorrect: true
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2,
				text: {
					da: `Nej, fejning er fint`,
					en: ``
				},
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3,
				text: {
					da: `Ja, der skulle have været C-rengøring`,
					en: ``
				},
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4,
				text: {
					da: `Kun hvis jeg kan se rester af jord`,
					en: ``
				},
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							text: {
								da: `Så skal du helt sikkert afvise, men er du sikker på A-rengøring er nok?`,
								en: ``,
							}
						})
					})
				]
			})
		]
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 's1-m1-mc-gmo',
		layout: 's1-m1-gmo',
		subtype: 'images',
		background: 'outside',
		backgroundModifier: 'bottom',
		text: {
			da: `Du kan se i en chaufførs log, at et af de tre sidste læs var med sojaskrå GMO. Nu skal han læsse non-GMO økofoder. Hvilken af kørelisterne ville du kunne godkende?`,
			en: ``
		},
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1, isCorrect: true // Køreliste med x i tommeldt og B-rengøring
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2, // Køreliste uden x i tommeldt og med B-rengøring
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3, // Køreliste med x i tommeldt og A-rengøring
			})
		]
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 's1-m1-mc-eco-grain',
		background: 'outside',
		backgroundModifier: 'bottom',
		text: {
			da: `Må du tage imod øko-korn hvis chaufføren ikke kan fremvise en tro og love-erklæring?`,
			en: ``,
		},
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1,
				text: {
					da: `Nej`,
					en: ``
				},
				isCorrect: true
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2,
				text: {
					da: `Ja`,
					en: ``
				},
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3,
				text: {
					da: `Kun hvis det er bygmalt`,
					en: ``
				},
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4,
				text: {
					da: `Kun på fredage`,
					en: ``
				},
			})
		]
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		taskId: 's1-m1-instructions-copy-samples',
		background: 'outside',
		backgroundModifier: 'bottom',
		text: {
			da: `Husk også at tage kopiprøver af det, du modtager.`,
			en: ``
		}
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 's1-m1-mc-sacks',
		layout: 's1-m1-sacks',
		background: 'outside',
		backgroundModifier: 'bottom',
		subtype: 'images',
		text: {
			da: `Lad os kigge på modtagelse af sække. Hvilke af disse sække skal du afvise ved modtagelse?`,
			en: ``,
		},
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1, // sack with hole
				isCorrect: true
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2, // sack with no label
				isCorrect: true,
				
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3, // sack with label, no hole
			})
		]
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 's1-m1-mc-fifu',
		text: {
			da: `Når du sætter sække på lageret gælder FIFU-princippet. Hvad betyder det?`,
			en: ``,
		},
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1,
				text: {
					da: `Først ind først ud`,
					en: ``
				},
				isCorrect: true,
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							text: {
								da: `Ja! Placér sækkene, så de nyeste kommer ind bagved og bliver taget ud til sidst.`,
								en: ``,
							}
						})
					})
				]
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2,
				text: {
					da: `Fjern ikke fra udlevering`,
					en: ``
				}
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3,
				text: {
					da: `Først ind fjern ultimo`,
					en: ``
				}, 
			})
		]
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 's1-m1-mc-expired-sack',
		text: {
			da: `Hvad skal du gøre, hvis du modtager en sæk, som er udløbet?`,
			en: ``,
		},
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1,
				text: {
					da: `Mærk som kasseret, og send retur`,
					en: ``
				},
				isCorrect: true,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2,
				text: {
					da: `Kassér den`,
					en: ``
				}
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3,
				text: {
					da: `Ikke noget før datoen er overskredet med en måned`,
					en: ``
				}, 
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4,
				text: {
					da: `Lav en registrering af en nær-ulykke`,
					en: ``
				}, 
			})
		]
	}),
	Object.assign({}, taskTemplates.spotErrorsTemplate.mainTemplate, {
		taskId: 's1-m1-spot-errors-storage',
		layout: 's1-m1-storage',
		text: {
			da: `Det er den 23. september. Se om du kan spotte de 4 problemer på billedet. Tryk på dem. Tryk OK, når du er klar, eller hvis du ikke kan finde dem alle.`,
			en: ``,
		},
		errors: [
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {id: 'expired'}),
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {id: 'backside'}),
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {id: 'expired2'}),
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {id: 'floor'}),
		]
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		taskId: 's1-m1-instructions-samples-dates-content',
		text: {
			da: `Ved at tage prøver og holde styr på datoer og indhold, så sikrer vi, at vores kunder altid får råvarer af højeste kvalitet.`,
			en: ``
		}
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		taskId: 's1-m1-instructions-malted-barley-sample',
		characters: ['manager pose-2 coat-2'],
		text: {
			da: `Hvis du modtager maltbyg, så skal du tage en prøve umiddelbart efter modtagelsen.`,
			en: ``
		}
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 's1-m1-mc-when-downgrade-malted-barley',
		text: {
			da: `Hvornår skal maltbyg nedklassificeres til foderkorn?`,
			en: ``,
		},
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1,
				text: {
					da: `Hvis proteinindholdet er for højt eller lavt`,
					en: ``
				},
				isCorrect: true,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2,
				text: {
					da: `Hvis vandindholdet er for lavt`,
					en: ``
				},
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3,
				text: {
					da: `Hvis det er økologisk`,
					en: ``
				}, 
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4,
				text: {
					da: `Hvis det er af sorten Prospect`,
					en: ``
				},
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 5,
				text: {
					da: `Hvis det er af sorten Evergreen`,
					en: ``
				},
			})
		]
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		taskId: 's1-m1-instructions-almost-done',
		text: {
			da: `Du er snart færdig med træningen. Der mangler kun lidt opgaver om udlevering og vedligehold.`,
			en: ``
		}
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 's1-m1-mc-quality-grain-cleaning',
		text: {
			da: `Hvis du skal udlevere løsvarer eller kvalitetskorn, skal du tjekke, at vognen er rengjort. Men hvad er særligt i forhold til rengøring, når du udleverer kvalitetskorn - altså maltbyg og brødkorn?`,
			en: ``,
		},
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1,
				text: {
					da: `Hvis bilen ikke er ren, skal jeg nægte at læsse`,
					en: ``
				},
				isCorrect: true,
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							text: {
								da: `Netop! Er bilen ikke ren, skal der nægtes at læsse, indtil bilen er rengjort efter IDTF's minimumskrav.`,
								en: ``,
							}
						})
					})
				]
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2,
				text: {
					da: `Hvis bilen ikke er ren skal jeg notere det på udleveringssedlen`,
					en: ``
				}
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3,
				text: {
					da: `Hvis bilen ikke er ren, skal jeg notere det i vores log`,
					en: ``
				}, 
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4,
				text: {
					da: `Hvis bilen ikke er ren, skal jeg selv rengøre den`,
					en: ``
				}, 
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							text: {
								da: `Nej, det er chaufførens opgave.`,
								en: ``,
							}
						})
					})
				]
			})
		]
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		taskId: 's1-m1-instructions-cleaning-and-pests',
		text: {
			da: `Nu skal det handle om rengøring og skadedyr.`,
			en: ``
		}
	}),
	Object.assign({}, taskTemplates.sortTemplate.mainTemplate, {
		taskId: 's1-m1-sort-cleaning-frequency',
		text: {
			da: `I forhold til rengøring, hvor tit skal følgende så gøres? Sorter i de rigtige kasser.`,
			en: ``
		},
		categories: [
			Object.assign({}, taskTemplates.sortTemplate.categoryTemplate, {
				id: 1, title: {da: 'Ugentligt', en: 'Weekly'}
			}),
			Object.assign({}, taskTemplates.sortTemplate.categoryTemplate, {
				id: 2, title: {da: 'Månedligt', en: 'Monthly'}
			}),
			Object.assign({}, taskTemplates.sortTemplate.categoryTemplate, {
				id: 3, title: {da: 'Kvartalvis', en: 'Quarterly'}
			}),
			Object.assign({}, taskTemplates.sortTemplate.categoryTemplate, {
				id: 4, title: {da: 'Årligt', en: 'Yearly'}
			}),
		],
		items: [
			Object.assign({}, taskTemplates.sortTemplate.itemTemplate, {
				id: 1, text: {da: 'Vurdér om veje og asfaltpladser skal fejes', en: ''}, categoryIds: [1]
			}),
			Object.assign({}, taskTemplates.sortTemplate.itemTemplate, {
				id: 2, text: {da: 'Rengør udleveringsbånd', en: ''}, categoryIds: [1]
			}),
			Object.assign({}, taskTemplates.sortTemplate.itemTemplate, {
				id: 3, text: {da: 'Rengør påslag/redler/elevator', en: ''}, categoryIds: [2]
			}),
			Object.assign({}, taskTemplates.sortTemplate.itemTemplate, {
				id: 4, text: {da: 'Råvaresiloer', en: ''}, categoryIds: [2],
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							text: {
								da: `Rengør dem også mellem skift af partier, hvis det er nødvendigt.`,
								en: ``,
							}
						})
					})
				]
			}),
			Object.assign({}, taskTemplates.sortTemplate.itemTemplate, {
				id: 5, text: {da: 'Efterse og rengør brøndposer', en: ''}, categoryIds: [3]
			}),
			Object.assign({}, taskTemplates.sortTemplate.itemTemplate, {
				id: 6, text: {da: 'Nedfej/blæs planlager', en: ''}, categoryIds: [4],
			})
		]
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		taskId: 's1-m1-instructions-clean-if-needed',
		text: {
			da: `Hvis du vurderer, at der trænger til rengøring, så må du selvfølgelig gerne gøre det tiere. `,
			en: ``
		}
	}),
	Object.assign({}, taskTemplates.spotErrorsTemplate.mainTemplate, {
		taskId: 's1-m1-spot-errors-storage-2',
		layout: 's1-m1-storage-2',
		text: {
			da: `Se om du kan spotte de 3 problemer her. Tryk OK, når du er klar, eller hvis du ikke kan finde dem alle.`,
			en: ``,
		},
		errors: [
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {
				id: 'pidgeon',
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							text: {
								da: `Ja, ud med den. Den er sikkert fløjet ind af en port, der har stået åben.`,
								en: ``,
							}
						})
					})
				]
			}),
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {
				id: 'moth',
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							text: {
								da: `Godt set! Hvis der er møl i mølfælderne, så skal en skadedyrsbekæmper tilkaldes.`,
								en: ``,
							}
						})
					})
				]
			}),
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {
				id: 'gate',
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							text: {
								da: `Godt fanget! Hvis vi husker at lukke porte, der ikke er i brug, så er det sværere for skadedyr at komme ind.`,
								en: ``,
							}
						})
					})
				]
			}),
		]
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		taskId: 's1-m1-instructions-rodents-birds-insects',
		text: {
			da: `Gnavere, fugle og insekter kan forurene vores varer. Derfor er det vigtigt at holde øje med spor efter dem.`,
			en: ``
		}
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		taskId: 's1-m1-instructions-surveys',
		characters: ['manager pose-2 coat-2'],
		text: {
			da: `Til allersidst er der fire spørgsmål om fødevaresikkerhedskulturen på VJA. Her er der ikke rigtige og forkerte svar. Spørgsmålene giver ikke point, og besvarelserne er anonyme.`,
			en: ``
		}
	}),
	Object.assign({}, taskTemplates.surveyTemplate.mainTemplate, {
		taskId: 's1-m1-survey-1',
		shuffleOptions: false,
		text: {
			da: `<b>Hvor enig er du i følgende:</b>
				<br /><br />Jeg må og skal reagere, hvis jeg ser, at der er risiko for fødevaresikkerheden?`,
			en: ``
		},
		options: [
			Object.assign({}, taskTemplates.surveyTemplate.optionTemplate, {
				id: 1, text: {da: `Meget enig`, en: `Strongly agree`}
			}),
			Object.assign({}, taskTemplates.surveyTemplate.optionTemplate, {
				id: 2, text: {da: `Enig`, en: `Agree`}
			}),
			Object.assign({}, taskTemplates.surveyTemplate.optionTemplate, {
				id: 3, text: {da: `Hverken enig eller uenig`, en: `Neither agree nor disagree`}
			}),
			Object.assign({}, taskTemplates.surveyTemplate.optionTemplate, {
				id: 4, text: {da: `Uenig`, en: `Disagree`}
			}),
			Object.assign({}, taskTemplates.surveyTemplate.optionTemplate, {
				id: 5, text: {da: `Meget uenig`, en: `Strongly disagree`}
			}),
			Object.assign({}, taskTemplates.surveyTemplate.optionTemplate, {
				id: 6, text: {da: `Ved ikke`, en: `Don't know`}
			}),
		]
	}),
	Object.assign({}, taskTemplates.surveyTemplate.mainTemplate, {
		taskId: 's1-m1-survey-2',
		shuffleOptions: false,
		text: {
			da: `<b>Hvor enig er du i følgende:</b>
				<br /><br />Jeg reagerer hvis jeg ser andre, der ikke følger hygiejnereglerne?`,
			en: ``
		},
		options: [
			Object.assign({}, taskTemplates.surveyTemplate.optionTemplate, {
				id: 1, text: {da: `Meget enig`, en: `Strongly agree`}
			}),
			Object.assign({}, taskTemplates.surveyTemplate.optionTemplate, {
				id: 2, text: {da: `Enig`, en: `Agree`}
			}),
			Object.assign({}, taskTemplates.surveyTemplate.optionTemplate, {
				id: 3, text: {da: `Hverken enig eller uenig`, en: `Neither agree nor disagree`}
			}),
			Object.assign({}, taskTemplates.surveyTemplate.optionTemplate, {
				id: 4, text: {da: `Uenig`, en: `Disagree`}
			}),
			Object.assign({}, taskTemplates.surveyTemplate.optionTemplate, {
				id: 5, text: {da: `Meget uenig`, en: `Strongly disagree`}
			}),
			Object.assign({}, taskTemplates.surveyTemplate.optionTemplate, {
				id: 6, text: {da: `Ved ikke`, en: `Don't know`}
			}),
		]
	}),
	Object.assign({}, taskTemplates.surveyTemplate.mainTemplate, {
		taskId: 's1-m1-survey-3',
		shuffleOptions: false,
		text: {
			da: `<b>Hvor enig er du i følgende:</b>
				<br /><br />Hvis jeg ser et fødevaresikkerhedsproblem, så løser jeg det eller informerer min nærmeste leder?`,
			en: ``
		},
		options: [
			Object.assign({}, taskTemplates.surveyTemplate.optionTemplate, {
				id: 1, text: {da: `Meget enig`, en: `Strongly agree`}
			}),
			Object.assign({}, taskTemplates.surveyTemplate.optionTemplate, {
				id: 2, text: {da: `Enig`, en: `Agree`}
			}),
			Object.assign({}, taskTemplates.surveyTemplate.optionTemplate, {
				id: 3, text: {da: `Hverken enig eller uenig`, en: `Neither agree nor disagree`}
			}),
			Object.assign({}, taskTemplates.surveyTemplate.optionTemplate, {
				id: 4, text: {da: `Uenig`, en: `Disagree`}
			}),
			Object.assign({}, taskTemplates.surveyTemplate.optionTemplate, {
				id: 5, text: {da: `Meget uenig`, en: `Strongly disagree`}
			}),
			Object.assign({}, taskTemplates.surveyTemplate.optionTemplate, {
				id: 6, text: {da: `Ved ikke`, en: `Don't know`}
			}),
		]
	}),
	Object.assign({}, taskTemplates.surveyTemplate.mainTemplate, {
		taskId: 's1-m1-survey-4',
		shuffleOptions: false,
		text: {
			da: `<b>Hvor enig er du i følgende:</b>
				<br /><br />Hvis jeg oplyser om en risiko for fødevaresikkerheden, så bliver der gjort noget ved det?`,
			en: ``
		},
		options: [
			Object.assign({}, taskTemplates.surveyTemplate.optionTemplate, {
				id: 1, text: {da: `Meget enig`, en: `Strongly agree`}
			}),
			Object.assign({}, taskTemplates.surveyTemplate.optionTemplate, {
				id: 2, text: {da: `Enig`, en: `Agree`}
			}),
			Object.assign({}, taskTemplates.surveyTemplate.optionTemplate, {
				id: 3, text: {da: `Hverken enig eller uenig`, en: `Neither agree nor disagree`}
			}),
			Object.assign({}, taskTemplates.surveyTemplate.optionTemplate, {
				id: 4, text: {da: `Uenig`, en: `Disagree`}
			}),
			Object.assign({}, taskTemplates.surveyTemplate.optionTemplate, {
				id: 5, text: {da: `Meget uenig`, en: `Strongly disagree`}
			}),
			Object.assign({}, taskTemplates.surveyTemplate.optionTemplate, {
				id: 6, text: {da: `Ved ikke`, en: `Don't know`}
			}),
		]
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		taskId: 's1-m1-instructions-done',
		text: {
			da: `Sådan, du er nu igennem det her modul. Hvis du har fået tre stjerner, er du færdig med træningen. Hvis ikke, så må du tage modulet en gang til.`,
			en: ``
		}
	}),
];

let taskIdsArr = [];
moduleTasks.forEach((task, index) => {
	task.id = 's1-m1-' + (index + 1 < 10 ? '0' : '') + (index + 1);
	
	/* Check taskId is set when required and unique */
	if (task.isSolveToContinue) {
		if (task.taskId) {
			if (taskIdsArr.indexOf(task.taskId) >= 0) {
				console.error('Task id not unique: ', task.taskId);
			} else {
				taskIdsArr.push(task.taskId);
			}
		} else {
			console.error('Missing task id for task: ', task);
		}
	}
});


export {
	moduleTasks
};