import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import appConfig from 'config/app.config';
import {getText} from 'helpers/language-helper';
import {loginUiTexts} from 'data/ui-texts/login-ui-texts';
import {playerUiTexts} from 'data/ui-texts/player-ui-texts';
import {renderMarkdown} from 'helpers/text-helper';
import Button from 'components/ui/button/button';
import './player-lobby.scss';

const PlayerLobby = ({languageId, userData, gameData, switchPlayerGame, handleLogout}) => {

	const [altGameData, setAltGameData] = useState(null);

	/**
	 * Redirect to url
	 * @param {string} url 
	 */
	const handleGoToStore = (url) => {
		window.location.href = '/' + url;
	};

	/* Toggle "switch store" popup, track if currently switching store */
	const [userIdIsConfirmed, setUserIdIsConfirmed] = useState(false);
	const [showSwitchStorePopup, setShowSwitchStorePopup] = useState(false);
	const [isSwitchingStore, setIsSwitchingStore] = useState(false);

	/**
	 * Switch store
	 */
	const handleSwitchStore = () => {
		setIsSwitchingStore(true);
		switchPlayerGame(gameData.id).then(() => {
			window.location.reload();
		});
	};

	const getGame = () => {
		const db = firebase.firestore();
		db.collection(appConfig.gamesDbName).doc(userData.gameId).get().then((doc) => {
			if (doc.exists) {
				setAltGameData({id: doc.id, ...doc.data()});
			}
		});
	};

	/* Component did mount */
	useEffect(() => {
		getGame();
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
	

	/* No game related to url */
	if (!gameData) {
		return (
			<div className="PlayerLobby">
				<div className="PlayerLobby-logoutBtn">
					<Button 
						text={getText(loginUiTexts.logout, languageId)} 
						classes={['default']} 
						onClick={() => {handleLogout();}}
					/>
				</div>
				<div className="PlayerLobby-text">
					<span>{getText(playerUiTexts.noGame, languageId)}</span>
				</div>
			</div>
		);
	};

	/* Connected to different game (might be deleted), user id not confirmed */
	if (userData.gameId && !userIdIsConfirmed) {
		const connectedGameName = (altGameData 
			? altGameData.name 
			: getText(playerUiTexts.gameDeleted, languageId)
		);

		const text = JSON.parse(JSON.stringify(getText(playerUiTexts.playerDiffGameConfirmId, languageId)))
			.replace(/%email%/g, userData.email)	
			.replace(/%gameName%/g, connectedGameName);

		return (
			<div className="PlayerLobby">
				<div className="PlayerLobby-logoutBtn">
					<Button 
						text={getText(loginUiTexts.logout, languageId)} 
						classes={['default']} 
						onClick={() => {handleLogout();}}
					/>
				</div>
				<div className="PlayerLobby-title">{gameData.storeName}</div>
				<div className="PlayerLobby-text">{renderMarkdown(text)}</div>
				<div className="PlayerLobby-buttons">
					<div className="PlayerLobby-button">
						<Button 
							classes={['default']} 
							text={getText(playerUiTexts.yes, languageId)} 
							onClick={() => {setUserIdIsConfirmed(true);}} 
						/>
					</div>
					<div className="PlayerLobby-button">
						<Button 
							classes={['default']} 
							text={getText(playerUiTexts.noLogOut, languageId)} 
							onClick={() => {handleLogout();}} 
						/>
					</div>
				</div>
			</div>
		);
	}

	/* Connected to different game (might be deleted), user id confirmed */
	if (userData.gameId && userIdIsConfirmed) {
		const connectedGameName = (altGameData 
			? altGameData.name 
			: getText(playerUiTexts.gameDeleted, languageId)
		);

		const text = JSON.parse(JSON.stringify(getText(playerUiTexts.playerDiffGame, languageId)))
			.replace(/%gameName%/g, connectedGameName);
		const goToStoreBtnText = JSON.parse(JSON.stringify(getText(playerUiTexts.goToGameBtn, languageId)))
			.replace(/%gameName%/g, connectedGameName);
		const switchStoreBtnText = JSON.parse(JSON.stringify(getText(playerUiTexts.switchGameBtn, languageId)))
			.replace(/%gameName%/g, gameData.name);
		const switchStoreWarning = JSON.parse(JSON.stringify(getText(playerUiTexts.switchGameWarning, languageId)))
			.replace(/%gameName1%/g, connectedGameName)
			.replace(/%gameName2%/g, gameData.name);

		return (
			<div className="PlayerLobby">
				<div className="PlayerLobby-logoutBtn">
					<Button 
						text={getText(loginUiTexts.logout, languageId)} 
						classes={['default']} 
						onClick={() => {handleLogout();}}
					/>
				</div>
				<div className="PlayerLobby-title">{gameData.storeName}</div>
				<div className="PlayerLobby-text">{renderMarkdown(text)}</div>
				<div className="PlayerLobby-buttons">
					{altGameData && <div className="PlayerLobby-button">
						<Button 
							classes={['default']} 
							text={goToStoreBtnText} 
							onClick={() => {handleGoToStore(altGameData.url);}} 
						/>
					</div>}
					<div className="PlayerLobby-button">
						<Button 
							classes={['default']} 
							text={switchStoreBtnText} 
							onClick={() => {setShowSwitchStorePopup(true);}} 
						/>
					</div>
				</div>

				{showSwitchStorePopup && 
					<div className="PlayerLobby-popup" onClick={() => {setShowSwitchStorePopup(false);}}>
						<div className="PlayerLobby-popupContent" onClick={(e) => {e.stopPropagation();}}>
							<div className="PlayerLobby-popupTitle">{}</div>
							<div className="PlayerLobby-popupText">{renderMarkdown(switchStoreWarning)}</div>
							<div className="PlayerLobby-popupButtons">
								<Button 
									isLoading={isSwitchingStore}
									classes={['default']} 
									text={getText(playerUiTexts.confirmBtn, languageId)} 
									onClick={() => {handleSwitchStore();}} 
								/>
								<Button 
									classes={['default']} 
									text={getText(playerUiTexts.cancelBtn, languageId)} 
									onClick={() => {setShowSwitchStorePopup(false);}} 
								/>
							</div>
						</div>
					</div>
				}
			</div>
		);
	}

	/* Default page */
	return (
		<div className="PlayerLobby">
			<div className="PlayerLobby-logoutBtn">
				<Button 
					text={getText(loginUiTexts.logout, languageId)} 
					classes={['default']} 
					onClick={() => {handleLogout();}}
				/>
			</div>
			<p>{userData.id}</p>
		</div>
	);
};

PlayerLobby.propTypes = {
	languageId: PropTypes.string.isRequired,
	userData: PropTypes.object.isRequired,
	gameData: PropTypes.object,
	switchPlayerGame: PropTypes.func.isRequired,
	handleLogout: PropTypes.func.isRequired
};

export default PlayerLobby;
