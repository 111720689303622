let env = process.env.REACT_APP_ENV
	? process.env.REACT_APP_ENV
	: process.env.NODE_ENV;

if (!env) {
	console.error('ENV: ', env);
	env = 'development';
}

let gameUrl = 'http://localhost:3000';
let apiURL = 'http://localhost:8080/api/';
let usersDbName = 'users';
let gamesDbName = 'games';
let surveysDbName = 'surveys';
let minSurveyParticipants = 1;
let tenant =  'e14c005b-3876-49a9-9e6c-24b1b20490d0';
if (env === 'test' || env === 'demo') {
	gameUrl = 'cgl-vja-test.web.app';
	apiURL = 'https://cgl-vja-test.web.app/api/';
	if (env === 'demo') {
		gameUrl = 'cgl-vja-demo.web.app';
		apiURL = 'https://cgl-vja-demo.web.app/api/';
		gamesDbName = 'games-demo';
		surveysDbName = 'surveys-demo';
		minSurveyParticipants = 5;
	}
}
if (env === 'production') {
	gameUrl = 'vjahygienetraining.dk';
	apiURL = 'https://cgl-vja-production.web.app/api/';
	minSurveyParticipants = 5;
}



const appConfig = {
	env,
	usersDbName,
	gamesDbName,
	surveysDbName,
	gameUrl,
	apiURL,	
	name: 'VJA',
	tenant,
	faclitatorSectionUri: 'facilitator',
	cookiesAcceptedCookieName: 'vja_cookies_accepted',
	screenBaseWidth: 1920,
	defaultLanguage: 'da',
	maxStarsPerModule: 3,
	inactivityLimitSeconds: 30,
	moduleDisabledDays: 7,
	minSurveyParticipants
};

export default appConfig;