// NOTE: remember to update the UI-texts file on the drive, if you update this file */

const playerUiTexts = {
	/* Lobby */
	noGame: {
		da: 'Der findes ikke noget spil under denne URL',
		en: 'There is no game connected to this URL',
	},
	playerDiffGameConfirmId: {
		da: `Det ser ud til din e-mail %email% hører til på %gameName%. 
			<br /><br />Er du logget ind med den rigtige konto?`,
		en: `It looks like your e-mail %email% is connected to %gameName%.
			<br /><br />Are you logged in with the correct account?`,
	},	
	yes: {
		da: 'Ja',
		en: 'Yes',
	},
	noLogOut : {
		da: 'Nej, log ud',
		en: 'No, log out',
	},
	playerDiffGame: {
		da: `Du er allerede i gang med %gameName%.
			<br/><br/>Du kan kun være i gang med ét spil ad gangen.`,
		en: `You are currently connected to %gameName%.
			<br /><br />You can only be connected to one game at a time.`,
	},
	gameDeleted: {
		da: '??? (slettet)',
		en: '??? (deleted)',
	},
	goToGameBtn: {
		da: 'Gå til %gameName%',
		en: 'Go to %gameName%',
	},
	switchGameBtn: {
		da: 'Skift til %gameName%',
		en: 'Switch to %gameName%',
	},
	switchGameWarning: {
		da: `Du kan kun være i gang med ét spil ad gangen.
			<br /><br/>Hvis du skifter til %gameName2% vil al din data for %gameName1% blive slettet.
			<br /><br/>Er du sikker på, du vil skifte til %gameName2%?`,
		en: `You can only be connected to one game at a time.
			<br /><br />If you switch to %gameName2% all your progress in %gameName1% will be lost.
			<br /><br />Are you sure you want to switch to %gameName2%?`,
	},
	cancelBtn: {
		da: 'Afbryd',
		en: 'Cancel',
	},
	confirmBtn: {
		da: 'Ja, skift spil',
		en: 'Yes, switch game',
	},

	/* Choose language */
	chooseLanguage: {
		da: 'Vælg sprog',
		en: 'Choose language',
	},

	/* Module overview */
	reset: {
		da: 'Reset',
		en: 'Reset',
	},
	moduleOverview: {
		da: 'Moduloversigt',
		en: 'Module overview',
	},
	email: {
		da: 'E-mail',
		en: 'E-mail'
	},

	/* Module - general */
	continue: {
		da: 'Videre',
		en: 'Next',
	},
	ok: {
		da: 'OK',
		en: 'OK',
	},
	choose: {
		da: 'Vælg',
		en: 'Choose',
	},
	option: {
		da: 'svar',
		en: 'option',
	},
	options: {
		da: 'svar',
		en: 'options',
	},
	finishBtn: {
		da: 'Afslut',
		en: 'Finish',
	},

	/* Module - results */
	results: {
		da: 'resultat',
		en: 'results',
	},
	stars: {
		da: 'Stjerner',
		en: 'Stars',
	},
	points: {
		da: 'Point',
		en: 'Points',
	},
	timeSpent: {
		da: 'Tid brugt',
		en: 'Time spent',
	},
	avgErrors: {
		da: 'Antal fejl per opgave (gennemsnit)',
		en: 'Average mistakes per task',
	},
	noErrorsTasks: {
		da: 'Antal opgaver løst uden fejl',
		en: 'Number of tasks solved without mistakes',
	},
	backToModuleOverview: {
		da: 'Tilbage til moduloversigt',
		en: 'Back to module overview',
	},
	tryAgain: {
		da: 'Prøv igen',
		en: 'Try again',
	},
	confirmTryAgainPopup: {
		title: {
			da: 'Prøv igen',
			en: 'Try again'	,
		},
		text: {
			da: 'Vil du prøve at slå din egen score? Du kan nulstille modulet og spille igen.',
			en: 'Want to try to beat your own score? You can reset the module and play again.',
		},
		resetBtn: {
			da: 'Nulstil',
			en: 'Reset',
		},
		cancelBtn: {
			da: 'Afbryd',
			en: 'Cancel',
		}
	},

	/* Module - survey task */
	surveyChooseXOptions: {
		da: 'Vælg %X%',
		en: 'Choose %X%',
	},
	surveyChooseMaxXOptions: {
		da: 'Vælg op til %X%',
		en: 'Choose up to %X%',
	},

	/* Module - order task */
	orderPopup: {
		title: {
			da: 'Du fik %correctAnswers%/%totalAnswers%.',
			en: `You got %correctAnswers%/%totalAnswers%.`,
		},
		text: {
			da: 'Prøv igen!',
			en: `Try again!`,
		}
	},

	/* Module - spot errors task */
	spotErrorsPopup: {
		title1: {
			da: 'Ups!',
			en: 'Oops!',
		},
		text1: {
			da: 'Du fandt kun %spottedErrors% ud af %numberOfErrors% fejl.',
			en: 'You only %spottedErrors% of %numberOfErrors% errors.',
		},
		title2: {
			da: 'Ikke dårligt!',
			en: 'Not bad!',
		},
		text2: {
			da: 'Du fandt %spottedErrors% ud af %numberOfErrors% fejl.',
			en: 'You spotted %spottedErrors% of %numberOfErrors% errors.',
		},
		title3: {
			da: 'Godt klaret!',
			en: 'Well done!',
		},
		text3: {
			da: 'Du fandt %spottedErrors% ud af %numberOfErrors% fejl.',
			en: 'You spotted %spottedErrors% of %numberOfErrors% errors.',
		},
	},

	/* Module - talk to facilitator popup */
	talkToFacilitatorPopup: {
		text: {
			da: 'Du har lavet for mange fejl i modulet. Tal med kursuslederen om det videre forløb.',
			en: 'You have had too many errors in this module. Talk to your facilitator about how to proceed.',
		},
		okBtn: {
			da: 'Ok',
			en: 'Ok',
		}
	},

	/* Module - locked popup */
	moduleLockedPopup: {
		text: {
			da: `Du har lavet for mange fejl i modulet. Tal med kursuslederen om det videre forløb.
				<br /><br />Modulet er låst indtil %date%.`,
			en: `You have had too many errors in this module. Talk to your facilitator about how to proceed. 
				<br /><br />This module will be locked until %date%`,
		},
		okBtn: {
			da: 'Ok',
			en: 'Ok',
		}
	}
};

module.exports = {
	playerUiTexts
};