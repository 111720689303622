import {adminUiTexts} from 'data/ui-texts/admin-ui-texts';
import {loginUiTexts} from 'data/ui-texts/login-ui-texts';
import {getText} from './language-helper';

function getPlayerProgressColumns(languageId) {
	const playerProgressColumns = [
		{value: 'email', label: getText(loginUiTexts.email, languageId)},
		{value: 'name', label: getText(loginUiTexts.name, languageId)},
		{value: 'courseName', label: getText(adminUiTexts.playerProgress.course, languageId)},
		{value: 'startDate', label: getText(adminUiTexts.playerProgress.startDate, languageId)},
		{value: 'endDate', label: getText(adminUiTexts.playerProgress.endDate, languageId)}
	];

	return playerProgressColumns;
};

export function getDownloadSheet(languageId) {
	const sheet = {
		name: getText(adminUiTexts.playerProgress.employeeData, languageId),
		columns: getPlayerProgressColumns(languageId), 
		data: []
	};
	return sheet;
}