const scenariosData = [
	{
		id: 'scenario-1',
		title: {
			da: 'Lager',
			en: 'Storage',
		},
		languageIds: ['da'],
		modulesData: require('data/scenarios/scenario-1/modules-data').modulesData,
		moduleGroups: require('data/scenarios/scenario-1/modules-data').moduleGroups,
	},
	{
		id: 'scenario-2',
		title: {
			da: 'Transport',
			en: 'Transport',
		},
		languageIds: ['da'],
		modulesData: require('data/scenarios/scenario-2/modules-data').modulesData,
		moduleGroups: require('data/scenarios/scenario-2/modules-data').moduleGroups,
	},
	{
		id: 'scenario-3',
		title: {
			da: 'Produktion',
			en: 'Production',
		},
		languageIds: ['da'],
		modulesData: require('data/scenarios/scenario-3/modules-data').modulesData,
		moduleGroups: require('data/scenarios/scenario-3/modules-data').moduleGroups,
	},
];

export {
	scenariosData
};