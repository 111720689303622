import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';

/* Log environment */
let env = process.env.REACT_APP_ENV ? process.env.REACT_APP_ENV : process.env.NODE_ENV;
console.log('ENVIRONMENT: ', env);

let firebaseConfig = {};

/* Connect to firebase project test */
if (env === 'development' || env === 'test' || env === 'demo') {
	firebaseConfig = {
		apiKey: 'AIzaSyA4rkk3Zhol8L6zim3xOWKLcIFi-KYz9VE',
		authDomain: 'cgl-vja-test.firebaseapp.com',
		projectId: 'cgl-vja-test',
		storageBucket: 'cgl-vja-test.appspot.com',
		messagingSenderId: '1076853365505',
		appId: '1:1076853365505:web:de6c072dadaf09ecd89900'
	};	
}

/* Connect to firebase: production */
if (env === 'production') {
	firebaseConfig = {
		apiKey: 'AIzaSyCabm4g3oMb3rXjfRaZKMmc9nV46ZmaVXE',
		authDomain: 'cgl-vja-production.firebaseapp.com',
		projectId: 'cgl-vja-production',
		storageBucket: 'cgl-vja-production.appspot.com',
		messagingSenderId: '1040190776554',
		appId: '1:1040190776554:web:e791579d0e22e249c26393'
	};
}

/* Initialize firebase */
firebase.initializeApp(firebaseConfig);

export default firebase.firestore;