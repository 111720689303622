import React from 'react';
import PropTypes from 'prop-types';
import {usePreview} from 'react-dnd-preview';
import {getText} from 'helpers/language-helper';
import './order-dnd-preview.scss';

const OrderDndPreview = ({languageId, layout, subtype, itemsData}) => {
	let {display, item, style} = usePreview();
	if (!display) return null;

	/* Item data */
	const itemId = item.id;
	const itemData = itemsData.find((i) => {return i.id === itemId;});

	/* Position */
	var itemsDiv = document.getElementById('orderItems');
	if (itemsDiv) {
		const itemsWidth = itemsDiv.clientWidth;
		const em = (itemsWidth / 17.5);
		const offset = 12 * em;
		style.left = '-' + offset + 'px';
	}

	let className = 'OrderDndPreview-item' + (layout ? ' ' + layout : '');
	if (subtype) {
		className += ' ' + subtype;
		if (subtype === 'images') {
			className += ' item-' + itemData.id;
		}
	}
	
	return (
		<div 
			className={'OrderDndPreview'} 
			style={style}
		>
			<div className={className}>
				<span>{itemData && itemData.text ? getText(itemData.text, languageId) : null}</span>
			</div>
		</div>
	);
};

OrderDndPreview.propTypes = {
	languageId: PropTypes.string.isRequired,
	layout: PropTypes.string,
	subtype: PropTypes.string,
	itemsData: PropTypes.array.isRequired,
};

export default OrderDndPreview;